<template>
  <div class="p-4">
    <h5 class="text-bold mb-3">Agency Business Details</h5>
    <b-form-group id="name" label="Website Email:" label-for="input-1">
      <b-form-input
        id="input-1"
        v-model="form.contact_email"
        type="text"
        placeholder="Enter website name"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="address" label="Agency Address:" label-for="input-2">
      <b-form-textarea
        id="input-2"
        v-model="form.address"
        placeholder="9 Redwood, Lag...."
        rows="3"
        max-rows="8"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group id="linkedin" label="LinkedIn:" label-for="input-3">
      <b-form-input
        id="input-3"
        v-model="form.linkedin"
        type="text"
        placeholder="E.g https://linkedin.com/in/...."
      ></b-form-input>
    </b-form-group>

    <b-form-group id="x.com" label="X (formerly twitter):" label-for="input-4">
      <b-form-input id="input-4" v-model="form.twitter" type="text" placeholder="E.g https://x.com/...."></b-form-input>
    </b-form-group>

    <b-form-group id="instagram" label="Instagram:" label-for="input-5">
      <b-form-input
        id="input-5"
        v-model="form.instagram"
        type="text"
        placeholder="E.g https://instagram.com/...."
      ></b-form-input>
    </b-form-group>

    <b-form-group id="facebook" class="mb-5" label="Facebook:" label-for="input-6">
      <b-form-input
        id="input-6"
        v-model="form.facebook"
        type="text"
        placeholder="E.g https://facebook.com/...."
      ></b-form-input>
    </b-form-group>
    <div style="display: flex; gap: 20px;">
      <b-button type="submit" @click="goBack">Back to Previous</b-button>
      <b-button type="submit" variant="primary" @click="saveAndContinue">Save & Continue</b-button>
    </div>
  </div>
</template>

<script>
import { UPDATE_DFY_WEBSITE_MUTATION } from '~/graphql/mutations';
export default {
  name: 'AgencyBusinessForm',
  data: () => ({
    form: {
      id: null,
      twitter: '',
      linkedin: '',
      facebook: '',
      instagram: '',
      contact_email: '',
      address: '',
    },
  }),
  mounted() {
    this.getAgency();
  },
  methods: {
    async getAgency() {
      try {
        const url = `${process.env.VUE_APP_API_ROOT}/api/agencies/${this.$route.params.id}`;
        const data = await fetch(url);
        const response = await data.json();
        if (response?.agency) {
          this.updateForm(response?.agency);
        }
      } catch (error) {
        console.error(error);
      }
    },

    updateForm(form) {
      this.form.id = +this.$route.params.id;

      this.form.linkedin = form.linkedin;
      this.form.twitter = form.twitter;
      this.form.address = form.address;
      this.form.facebook = form.facebook;
      this.form.instagram = form.instagram;
      this.form.contact_email = form.contact_email;
    },

    async updateAgency() {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_DFY_WEBSITE_MUTATION,
          variables: { ...this.form, id: +this.$route.params.id },
        });
      } catch (error) {
        console.log(error);
      }
    },

    saveAndContinue() {
      // save the form
      this.updateAgency().then(() => {
        this.$router.push({
          query: {
            tab: 'agency-preview',
          },
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
