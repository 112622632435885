<template>
  <div class="auth-module reset">
    <div v-if="emailSent" class="shadow-sm p-5 text-center">
      <h4 class="font-weight-bold mb-3">Password Reset Instruction Sent!</h4>
      <p>
        Instruction on how to reset your password has been sent to your email address. Please check your email for more
        details.
      </p>
    </div>
    <template v-else>
      <h4 class="font-weight-bold mb-4">Forgot your password?</h4>

      <p class="mb-2">Enter your email address below and we'll get you back on track.</p>

      <b-form @submit.prevent="sendToken" @keydown="form.onKeydown($event)">
        <b-form-group class="mb-4">
          <b-form-input
            name="email"
            v-model="form.email"
            placeholder="Your email address"
            type="email"
            required
          ></b-form-input>
          <has-error :form="form" field="email" />
        </b-form-group>

        <b-button
          class="py-3 font-weight-bold text-uppercase"
          variant="primary"
          type="submit"
          block
          :disabled="form.busy"
        >
          <b-spinner class="mr-2" v-if="form.busy" small />
          Continue
        </b-button>
      </b-form>
    </template>
    <div class="text-center mt-3">
      Back to
      <router-link :to="{ name: 'signin' }">Log In</router-link>
    </div>
  </div>
</template>

<script>
import Form from 'vform';

import { SEND_PASSWORD_RESET_EMAIL_MUTATION } from '~/graphql/mutations';

export default {
  name: 'Reset',

  metaInfo() {
    return { title: 'Reset Password' };
  },

  data() {
    return {
      emailSent: false,
      form: new Form({
        email: '',
      }),
    };
  },

  methods: {
    async sendToken() {
      this.form.busy = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SEND_PASSWORD_RESET_EMAIL_MUTATION,
          variables: {
            email: this.form.email,
          },
          context: {
            uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
          },
        });

        this.emailSent = data.sendPasswordResetEmail;
      } catch ({ graphQLErrors: errors }) {
        this.form.busy = false;
        for (let i = 0; i < errors.length; i++) {
          if (errors[i].message == 'NoEmail') {
            this.form.errors.set('email', 'Account with this email does not exist');
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.auth-module {
  &.reset {
    padding-top: 120px;
  }
}
</style>
