<template>
  <div class="wizard-steps">
    <ol class="wizard">
      <li v-for="(step, i) in steps" tabindex="1" :key="i" class="wizard__list">
        <router-link
          :to="step.route"
          class="wizard__item"
          :title="step.desc"
          tag="button"
          :class="{
            'wizard__item--active': step.id === current,
            'wizard__item--visited': isVisited(step),
          }"
          disabled="true"
        >
          <span class="wizard__item-icon">
            <svg-icon :name="step.icon_name" size="md" />
          </span>
          <div class="wizard__item-desc">
            <span class="item-count">Step {{ step.id }}/{{ steps.length }}</span>
            <p class="item-text">{{ step.desc }} {{ current }}</p>
          </div>
        </router-link>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      requried: true,
    },
  },
  data() {
    return {
      steps: [
        {
          id: 1,
          desc: 'Business Details',
          icon_name: 'business',
          route: { name: 'business.create' },
        },
        {
          id: 2,
          desc: 'Brand Details',
          icon_name: 'brand',
          route: { name: 'brand.create' },
        },
        {
          id: 3,
          desc: 'Team Members',
          icon_name: 'team-alt',
          route: { name: 'teams' },
        },
      ],
    };
  },
  methods: {
    isVisited(step) {
      return step.id <= this.current;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.wizard {
  list-style: none;
  margin: 0;
  padding: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  overflow: hidden;
  border: 2px solid #b7e4c7;
  border-radius: 30px;
  background-color: transparent;
  width: 100%;

  .wizard__list {
    border-left: 2px solid #b8b8b8;
    &:first-child {
      border: none;
    }
  }

  .wizard__item {
    background-color: transparent;
    border: none;
    min-width: 100px;
    line-height: 15px;
    display: flex;
    align-items: left;
    text-align: left;
    flex-wrap: nowrap;
    position: relative;
    padding: 10px;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &-icon {
      width: 50px;
      height: 50px;
      line-height: 25px;
      vertical-align: middle;
      border-radius: 50%;
      background-color: #eff5f0;
      text-align: center;
      margin: 0 10px;
      padding: 13px 0 0 0;
    }

    &--visited,
    &--active {
      .wizard__item-icon {
        background: #74c69d;
        color: #fff;
      }
      .wizard__item-desc {
        .item-count {
          color: #74c69d;
        }
        .item-text {
          color: $primary;
        }
      }
    }

    &-desc {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 10px;
      .item-count {
        font-size: 12px;
        color: gray;
      }

      .item-text {
        margin-top: 6px;
        font-size: 12px;
        color: gray;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &--disabled,
    [disabled] {
      background-color: #f5f7fa; // gray l30
      color: #c0c0c0; // gray d20
      cursor: default;
    }
  }
}
</style>
