const fonts = [
  {
    name: 'Archivo Black',
    fontFamily: 'Archivo Black',
    type: 'google',
  },
  {
    name: 'Abril Fatface',
    fontFamily: 'Abril Fatface',
    type: 'google',
  },
  {
    name: 'Cantata One',
    fontFamily: 'Cantata One',
    type: 'google',
  },
  {
    name: 'Fjalla One',
    fontFamily: 'Fjalla One',
    type: 'google',
  },
  {
    name: 'Lato',
    fontFamily: 'Lato',
    type: 'google',
  },
  {
    name: 'Quicksand',
    fontFamily: 'Quicksand',
    type: 'google',
  },
  {
    name: 'Rakkas',
    fontFamily: 'Rakkas',
    type: 'google',
  },
  {
    name: 'Roboto Mono',
    fontFamily: 'Roboto Mono',
    type: 'google',
  },
  {
    name: 'Vollkorn',
    fontFamily: 'Vollkorn',
    type: 'google',
  },
  {
    name: 'Adobe Caslon',
    fontFamily: 'adobe-caslon-w01-smbd',
    type: 'custom',
  },
  {
    name: 'Amatic SC',
    fontFamily: 'amatic sc',
    type: 'custom',
  },
  {
    name: 'Anton',
    fontFamily: 'anton',
    type: 'google',
  },
  {
    name: 'Arial',
    fontFamily: 'arial',
    type: 'google',
  },
  {
    name: 'Arial Black',
    fontFamily: 'arial black',
    type: 'google',
  },
  {
    name: 'Avenida',
    fontFamily: 'avenida-w01',
    type: 'custom',
  },
  {
    name: 'Basic',
    fontFamily: 'basic',
    type: 'google',
  },
  {
    name: 'Belinda',
    fontFamily: 'belinda-w00-regular',
    type: 'custom',
  },
  {
    name: 'Bodoni Poster',
    fontFamily: 'bodoni-w01-poster',
    type: 'custom',
  },
  {
    name: 'Montserrat',
    fontFamily: 'Montserrat',
    type: 'google',
  },
];

export default fonts;
