<template>
  <div>
    <WorkspaceHeader />
    <div class="brand__view">
      <b-row class="py-4 pl-4">
        <b-col>
          <b-row class="align-items-center">
            <!--<b-col cols="4">
              <div class="brand__view-search-input">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text px-3 d-flex align-items-center justify-content-center"
                      id="search-addon1"
                    >
                      <svg-icon name="search" style="color: #576f7f;" />
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    aria-label="search"
                    aria-describedby="search-addon1"
                  />
                </div>
              </div>
            </b-col>-->
            <b-col>
              <b-row>
                <b-col cols="10" class="mx-auto">
                  <Banner />
                </b-col>
              </b-row>
              <div>
                <!---<div class="brand-title">Your Brands</div> -->
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <div class="mb-3">
              <h4 class="font-weight-bold pl-5 mb-0 pb-0">
                Your Brand
              </h4>
              <div class="m-0 p-0 f-12 pl-5">Checkout your created brands below</div>
            </div>
            <b-container class="mb-3 ml-4">
              <b-row cols="3">
                <b-col class="brand__view-items">
                  <div class="brand add_brand">
                    <div class="brand-title">Create a Brand</div>
                    <b-button
                      variant="light"
                      @click="$router.push('/workspaces/create')"
                      class="add-workspace-btn m-0 p-0"
                    >
                      <svg-icon name="add-circle" size="xl" />
                    </b-button>
                    <div class="create-desc f-13 mt-3">
                      e.g: Tronweb, Nike, Pepsi
                    </div>
                  </div>
                </b-col>

                <template v-if="$apollo.queries.fetchWorkspaces.loading">
                  <b-col class="brand__view-items" v-for="(number, i) in 5" :key="`dummy-load-${i}`">
                    <div class="brand add_brand">
                      <skeleton-screen height="20px" width="100px" class="mx-auto mt-4 mb-3" />
                      <skeleton-screen height="30px" width="200px" class="mx-auto rounded mb-4" />
                      <skeleton-screen height="14px" width="180px" class="mx-auto" />
                    </div>
                  </b-col>
                </template>

                <template v-if="fetchWorkspaces.length">
                  <b-col class="brand__view-items" v-for="workspace in fetchWorkspaces" :key="workspace.id">
                    <div
                      class="brand"
                      :class="getRandomColor(workspace.id)"
                      @click="openWorkspace(workspace)"
                      style="cursor: pointer;"
                      title="click to open workspace"
                    >
                      <div class="brand-data">
                        <div>
                          <span class="created">Created</span>
                          <p class="datetime">{{ workspace.createdAt | createdAt }}</p>
                        </div>
                        <div class="brand-menu">
                          <b-dropdown dropleft no-caret text="Drop-Left" variant="link" class="m-0 workspace-menu-btn">
                            <template #button-content>
                              <div class="" title="...more">
                                <svg-icon name="h-elipsis" size="md" />
                                <span class="sr-only">Menu</span>
                              </div>
                            </template>
                            <b-button
                              v-b-tooltip.hover
                              title="Settings"
                              :to="{ name: 'settings.brand', params: { workspace: workspace.slug } }"
                              variant="outline-primary"
                              size="sm"
                              class="m-1"
                              @click.stop
                            >
                              <svg-icon name="settings" />
                            </b-button>
                            <b-button
                              v-b-tooltip.hover
                              title="Delete"
                              variant="outline-danger"
                              size="sm"
                              class="m-1"
                              @click.stop="removeWorkspace(workspace)"
                            >
                              <svg-icon name="trash" />
                            </b-button>
                          </b-dropdown>
                        </div>
                      </div>
                      <div class="brand-details my-2">
                        <b-avatar class="avatar" size="4rem" :src="workspace.logo" />
                        <div class="brand-title">
                          {{ workspace.name }}
                        </div>
                      </div>
                      <div class="brand-data">
                        <div class="brand-members">
                          <div
                            class="brand-members-list"
                            v-for="(member, index) in formatMembers(workspace.members)"
                            :key="member.id"
                          >
                            <div class="member-img-container" v-if="!member.indicator">
                              <b-avatar
                                v-b-tooltip.hover
                                :src="member.photo"
                                :title="member.name"
                                class="avatar my-2 member_avatar"
                                :class="{ not_first_item: index !== 0 }"
                                size="2.2rem"
                                :alt="`${member.firstName} ${member.lastName}`"
                              ></b-avatar>
                            </div>
                            <p v-else class="text">{{ member.more }}</p>
                          </div>
                          <b-button
                            variant="success"
                            size="sm"
                            title="Add member to workspace"
                            class="add-member-btn m-0 p-0"
                            @click.stop="handleAddMember(workspace)"
                          >
                            <svg-icon name="add-circle" size="md" />
                          </b-button>
                        </div>
                        <div class="ml-auto">
                          <b-button
                            v-if="workspace.default"
                            variant="default"
                            class="default-btn brand-default"
                            title="Default"
                          >
                            Default
                          </b-button>
                          <b-button
                            v-else
                            variant="default"
                            class="default-btn set-default"
                            title="Set Workspace to be Default"
                            @click.stop="setDefaultWorkspace(workspace)"
                            size="sm"
                          >
                            Set as Default
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </template>
              </b-row>
            </b-container>
          </b-row>

          <!-- Delete Workspace Modal -->
          <b-modal
            id="DeleteWorkspaceModal"
            content-class="text-center"
            body-class="py-4"
            centered
            hide-header
            hide-footer
            @hidden="onHideDeleteWorkspaceModal"
          >
            <template v-slot:default="{ hide }">
              <template v-if="!deletingWorkspace">
                <h5 class="font-weight-bold mb-3">Delete Workspace</h5>
                <p class="m-1">By deleting this workspace, their:</p>

                <div class="bg-light p-4 text-left mb-3">
                  <ul>
                    <li>Blogs/Accounts will be deleted.</li>
                    <li>Integrations will be deleted.</li>
                    <li>Curated Topics and Custom Topics will be deleted.</li>
                    <li>Favorite Folders will be deleted.</li>
                    <li>Your added sources will be deleted from this workspace.</li>
                    <li>Automation Campaigns will be deleted.</li>
                    <li>Plans will be deleted.</li>
                    <li>Publications will be deleted.</li>
                    <li>Comment, task and other activities will be deleted.</li>
                  </ul>
                </div>
                <p class="m-3">This action cannot be undone.</p>

                <div>
                  <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
                  <b-button variant="danger" class="px-3" size="sm" @click="deleteWorkspace">Delete</b-button>
                </div>
              </template>
              <div v-else class="p-5"><spinner size="4" /></div>
            </template>
          </b-modal>

          <!-- Add Team Member Modal -->
          <b-modal
            id="AddTeamMemberModal"
            modal-class="add_member_modal"
            hide-header
            hide-footer
            centered
            size="lg"
            v-model="showAddMemberModal"
            @hidden="hideAddMemberModal"
            :data="activeWorkspace"
          >
            <template v-slot:default="{ hide }">
              <b-button variant="clear" class="float-right close-btn" @click="hide()">
                <svg-icon name="close" size="md" />
              </b-button>
              <div class="p-4">
                <h5 class="font-weight-bold mb-0 pb-0">
                  Add Team Members to
                  <span class="text-primary text-uppercase">{{ activeWorkspace.slug }}</span> Workspace
                </h5>
                <span class="m-0 p-0 f-12">Please fill in the details below</span>

                <b-form
                  class="team__members-add"
                  @submit.prevent="addTeamMember(activeWorkspace)"
                  @keydown="form.onKeydown($event)"
                >
                  <b-row class="mt-3">
                    <b-col>
                      <b-form-group>
                        <label for="first_name-field" class="f-14">First Name</label>
                        <b-form-input
                          id="first_name-field"
                          v-model="form.firstName"
                          type="text"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <label for="last_name-field" class="f-14">Last Name</label>
                        <b-form-input
                          id="last_name-field"
                          v-model="form.lastName"
                          type="text"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col>
                      <b-form-group class="">
                        <label for="email-field" class="f-14">Email Address</label>
                        <b-form-input
                          id="email-field"
                          v-model="form.email"
                          type="email"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col>
                      <b-form-group>
                        <label for="type-field" class="f-14">User Type</label>
                        <b-form-select v-model="form.type" id="user-type" size="sm" value="admin">
                          <option value="team">Team</option> <option value="client">Client</option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <label for="role-field" class="f-14">User Role</label>
                        <b-form-select v-model="form.role" id="member-role" size="sm" value="admin">
                          <option value="admin">Administrator</option>
                          <option value="collaborator">Collaborator</option>
                          <option value="approver">Approver</option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-button type="submit" class="team__members-btn shadow mt-4" :disabled="addingMember">
                    <b-spinner v-if="addingMember" class="mr-1" small></b-spinner>
                    Save
                  </b-button>
                </b-form>
              </div>
            </template>
          </b-modal>
        </b-col>
        <b-col cols="3" class="m-0">
          <div class="pt-2 pr-4">
            <ChatBar />
          </div>
        </b-col>
      </b-row>
    </div>
    <GeneralTutorialModal :visible="showTutorialModal" />
  </div>
</template>

<script>
import Form from 'vform';
import moment from 'moment';
import { mapState, createNamespacedHelpers } from 'vuex';
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';
import ChatBar from '~/components/General/Menus/ChatBar.vue';
import GeneralTutorialModal from '~/components/GeneralTutorialModal';
import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import {
  ADD_TEAM_MEMBER_MUTATION,
  DELETE_WORKSPACE_MUTATION,
  SET_DEFAULT_WORKSPACE_MUTATION,
} from '~/graphql/mutations';
import Banner from '~/components/General/Banner.vue';
// import { get, isObject } from 'lodash';
import app from '~/main';

const accessControl = createNamespacedHelpers('accessControl');

export default {
  components: { WorkspaceHeader, ChatBar, GeneralTutorialModal, Banner },

  data() {
    return {
      fetchWorkspaces: [],
      invokedForDelete: null,
      deletingWorkspace: false,
      addingMember: false,
      form: new Form({
        firstName: '',
        lastName: '',
        email: '',
        role: 'admin',
        type: 'team',
      }),
      showAddMemberModal: false,
      activeWorkspace: null,
      colorCache: [],
      colors: ['#d8f3dc', '#ffddaa', '#ffd2d2', '#e4fcff', '#5060b8', '#c4ac78'],
    };
  },

  apollo: {
    fetchWorkspaces: {
      query: FETCH_WORKSPACES_QUERY,
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authBusy: state => state.auth.busy,
      showTutorialModal: state => state.auth.user.options.onboarding.homepage,
    }),

    ...accessControl.mapGetters(['isUserReseller', 'isUserAdminInWorkspace', 'isUserClientInWorkspace']),
  },

  async beforeRouteEnter(to, from, next) {
    if (to.query.error && to.query.error === 'callback_error') {

      app.$notify({
        group: 'main',
        type: 'error',
        text: 'An error occurred while processing your request. Try again or contact system admin',
      });

      const removeErrorQueries = route => {
        return Object.fromEntries(
          Object.entries(route.query).filter(([key]) => key !== 'error')
        );
      };

      const query = removeErrorQueries(to);
      app.$router.push({ query }, () => {
        next();
      });
    }

    next();

  },

  filters: {
    createdAt(value) {
      if (!value) return '';

      return moment(value).format('MMM D, YYYY');
    },
  },

  created() {},
  methods: {

    getColor(index) {
      let bgColor = '';
      for (let i = 0; i < this.colors.length; i++) {
        if (i === index) bgColor = this.colors[i];
      }
      return bgColor;
    },
    openWorkspace(workspace) {
      this.$router.push({ name: 'dashboard', params: { workspace: workspace.slug } });
    },

    formatMembers(members) {
      if (members.length > 3) {
        // Cut out 3 and add more indicator
        const slicedOut = members.slice(0, 2);
        return [
          ...slicedOut,
          {
            id: 'more',
            indicator: true,
            more: `+${members.length - slicedOut.length}`,
          },
        ];
      }

      return members;
    },

    setDefaultWorkspace(workspace) {
      this.$store.dispatch('layout/updateRestless', true);

      this.$apollo
        .mutate({
          mutation: SET_DEFAULT_WORKSPACE_MUTATION,
          variables: {
            id: workspace.id,
          },
          update: (store, { data: { setDefaultWorkspace } }) => {
            if (!setDefaultWorkspace) return;

            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              data.fetchWorkspaces = data.fetchWorkspaces.map(space => ({
                ...space,
                default: space.id === setDefaultWorkspace.id,
              }));

              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$store.dispatch('layout/updateRestless', false);
        })
        .catch(() => {
          this.$store.dispatch('layout/updateRestless', false);

          this.$notify({
            group: 'main',
            type: 'error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    deleteWorkspace() {
      if (!this.invokedForDelete) return;

      this.deletingWorkspace = true;

      this.$apollo
        .mutate({
          mutation: DELETE_WORKSPACE_MUTATION,
          variables: {
            id: this.invokedForDelete.id,
          },
          update: (store, { data: { deleteWorkspace } }) => {
            if (!deleteWorkspace) return;

            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              const index = data.fetchWorkspaces.findIndex(w => w.id === this.invokedForDelete.id);

              if (index != -1) {
                data.fetchWorkspaces.splice(index, 1);
                // Write our data back to the cache.
                store.writeQuery({
                  query: FETCH_WORKSPACES_QUERY,
                  data,
                });
              }
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          // TODO: fix this
          // const usedCount = parseInt(this.user.subscription.limitsUsage.workspaces);
          // this.$store.dispatch('auth/updateUser', {
          //   user: {
          //     ...this.user,
          //     subscription: {
          //       ...this.user.subscription,
          //       limitsUsage: {
          //         ...this.user.subscription.limitsUsage,
          //         workspaces: usedCount - 1,
          //       },
          //     },
          //   },
          // });

          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Workspace deleted',
            text: 'The workspace was deleted successfully.',
          });

          this.$bvModal.hide('DeleteWorkspaceModal');
        })
        .catch(error => {
          this.deletingWorkspace = false;

          this.$notify({
            group: 'main',
            type: 'error',
            text: error?.graphQLErrors[0]?.debugMessage || 'An error occurred while processing your request.',
          });
        });
    },

    removeWorkspace(workspace) {
      if (workspace.default) {
        return this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'You cannot delete your default workspace.',
        });
      }

      this.invokedForDelete = workspace;

      this.$bvModal.show('DeleteWorkspaceModal');
    },

    addTeamMember(activeWorkspace) {
      const user = this.user;
      const sub = user ? user.subscription : null;
      const limit = parseInt(sub ? sub.plan.limits.members : '');
      const limitCount = isNaN(limit) ? Infinity : limit;
      const memberCount = activeWorkspace.members.length;
      const check = memberCount >= limitCount;

      if (check) {
        this.$notify({
          group: 'main',
          type: 'native-error',
          title: 'Please Upgrade',
          text: `You have exceeded the maximum number of team members allowed in the <strong>${activeWorkspace.name}</strong> workspace.
            Please upgrade your subscription to be able to add more team members.`,
        });
        return;
      }

      this.addingMember = true;

      this.$apollo
        .mutate({
          mutation: ADD_TEAM_MEMBER_MUTATION,
          variables: {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            email: this.form.email,
            role: this.form.role,
            type: this.form.type,
            workspaces: activeWorkspace.id,
          },
          update: (store, { data: { addTeamMember } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              const spaceIndex = data.fetchWorkspaces.findIndex(w => w.id === activeWorkspace.id);

              if (spaceIndex !== -1) {
                const workspace = data.fetchWorkspaces[spaceIndex];
                workspace.members.push(...addTeamMember);
              }
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.addingMember = false;
          this.$store.dispatch('layout/updateRestless', false);

          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Success!',
            text: 'Team member has been added successfully.',
          });

          this.showAddMemberModal = false;

          this.form.reset();
        })
        .catch(() => {
          this.addingMember = false;
        });
    },

    onHideDeleteWorkspaceModal() {
      this.deletingWorkspace = false;
      this.invokedForDelete = null;
    },

    handleAddMember(workspace) {
      this.activeWorkspace = workspace;
      this.showAddMemberModal = true;
    },

    hideAddMemberModal() {
      this.activeWorkspace = null;
      this.form.workspace = '';
      this.showAddMemberModal = false;
      this.form.reset();
    },

    getRandomColor(workspace) {
      var N = 0;
      const colors = ['first_color', 'second_color', 'third_color', 'fourth_color', 'fifth_color'];
      const sort = Math.floor(Math.random() * (colors.length - N));
      N = Math.min(N + 1, 5);
      const activeColor = colors.splice(sort, 1);
      return this.colorCache[workspace] || (this.colorCache[workspace] = activeColor);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.brand__view-items .brand-menu ul.dropdown-menu.show {
  padding: 5px !important;
  min-width: auto !important;
  display: flex;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
}
.brand__view {
  padding-top: $navbar-height;
  &-search-input {
    margin: 15px;
    .input-group {
      color: #b2b2b2 !important;
      border-radius: 15px !important;
      background-color: #f2f2f2 !important;
      padding: 4px;
      font-size: 12px;
      &::placeholder {
        color: #b2b2b2;
      }
    }
    input,
    .input-group-prepend,
    .input-group-text {
      background-color: transparent;
      // border: none;

      outline: none;
      color: $secondary;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        outline: none;
      }
    }
    .input-group-text {
      padding: 0.3rem;
    }
  }
  &-brand-title {
    overflow: hidden;
    text-align: center;
    font-size: 14px;

    &:before,
    &:after {
      background-color: #b2b2b2;
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }

  &-items {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    .brand {
      margin: 5px 10px 20px 0;
      padding: 15px 20px;
      border-radius: 10px;
      width: 100%;
      height: 210px;
      position: relative;
      &-title {
        font-size: 20px;
        padding: 10px 0;
        font-weight: bold;
        margin-left: 10px;
        text-transform: capitalize;
      }
      &-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .created {
          font-size: 10px;
          margin: 0;
          padding: 0;
        }
        .datetime {
          font-size: 14px;
          margin: 0;
          padding: 0;
          font-weight: bold;
        }
        .default-btn {
          padding: 5px 10px;
          border-radius: 20px;
          font-size: 14px;
        }
        .brand-default {
          background-color: #52b788;
          color: #d8f3dc;
        }
        .set-default {
          background-color: transparent;
          border: 2px solid #52b788;
          display: none;
        }
      }
      &-menu {
        .dropdown-menu {
          min-width: 5rem !important;
          border: 0 !important;
          text-align: center !important;
        }
      }
      &-details {
        display: flex;
        align-items: center;
      }
      &-members-list {
        display: inline-flex;
        gap: 0px;
      }
      &-members {
        .member-img-container {
          position: relative;
          // margin-left: -2px;
        }
        .member_avatar {
          position: relative;
          border: 1px solid #f2f2f2;
          // &.not_first_item {
          //   left: -10px;
          // }
        }

        .add-member-btn {
          border-radius: 100%;
          text-align: center;
          margin-left: 4px;
          width: 2.2rem;
          height: 2.2rem;
          line-height: 1;
          vertical-align: middle;
          svg {
            fill: $white;
            margin-top: 1px;
          }
        }
      }
      &:hover {
        .set-default {
          display: block;
        }
      }
    }
  }
  .add_brand {
    background-color: #e5e5e5;
    text-align: center;
    .add-workspace-btn {
      width: 70px;
      height: 70px;
      line-height: 70px;
      border-radius: 100%;
      text-align: center;
      margin: 10px auto;
      vertical-align: middle;
      svg {
        fill: #95d5b2;
      }
    }
  }
  .first_color {
    background: #d8f3dc;
  }
  .second_color {
    background: #ffddaa;
  }
  .third_color {
    background: #ffd2d2;
  }
  .fourth_color {
    background: #f8b95b;
  }
  .fifth_color {
    background: #e4fcff;
  }
  .brand-action-menu {
    display: inline-block;
    position: relative;
    &__button {
      background: transparent;
      display: inline-block;
      cursor: pointer;
      padding: 0 10px;
    }
    &__menu-box {
      position: absolute;
      width: 100%;
      min-width: 100px;
      max-width: 100px;
      left: -110px;
      top: -28px;
      background-color: $primary;
      border-radius: 20px;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      visibility: hidden;
      opacity: 0;
      padding: 10px;
      &:before {
        content: '';
        background-color: transparent;
        position: absolute;
        border-bottom: 12px solid transparent;
        border-right: 12px solid transparent;
        border-left: 12px solid $primary;
        border-top: 12px solid transparent;
        top: 25%;
        right: -22px;
      }
      &::after {
        content: '';
        background-color: transparent;
      }
      &-left {
        border-right: 1px solid #e0e2e9;
      }
    }
    &__menu {
      margin: 0;
      list-style: none;
      display: flex;
    }
    &__item {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      &:hover {
        background-color: #2d6a4f;
        border: 1px solid #d8f3dc;
        border-radius: 5px;
      }
      .item-link {
        margin: 4px;
      }
    }
  }
  .workspace-menu-btn {
    .dropdown-toggle {
      margin-right: -20px;
    }
  }
  .brand-action-menu--active .brand-action-menu__menu-box {
    visibility: visible;
    opacity: 1;
    margin-top: 15px;
  }
  .close-btn {
    svg {
      fill: $secondary;
    }
  }
}
</style>
