<template>
  <div class="niches__page">
    <WorkspaceHeader />
    <div class="niches__page-view">
      <div class="my-5"></div>
      <div class="search-input col-8 mx-auto">
        <input class="" type="search" placeholder="Type Keyword Here" @input="handleNicheSearch" />
        <svg-icon name="search" class="text-primary mr-3 mt-2" size="sm" />
      </div>

      <div class="niches-container bg-gray-200 px-3 rounded-lg bg-img wave-bg m-4">
        <b-row cols-lg="3" col-md="2" col-sm="1">
          <b-col v-for="niche in filteredNiches" :key="niche.id" class="my-4 align-items-stretch">
            <b-card class="niche-single-card h-100 align-content-start" @click="handleViewSection(niche)">
              <b-card-img-lazy
                :src="niche.image_url"
                :alt="niche.name"
                fluid-grow
                blank
                blank-src=""
                blank-color="transparent"
                class="overflow-hidden rounded-lg niche-image"
              >
              </b-card-img-lazy>

              <div class="px-2">
                <b-row class="my-3">
                  <b-col col-md="8" class="text-left">
                    <b-card-text class="mb-0 pb-0 font-weight-bold f-16 text-capitalize">
                      {{ niche.name }}
                    </b-card-text>
                  </b-col>
                  <b-col col-md="4" class="text-right">
                    <svg
                      class="section-icon icon-star"
                      width="20"
                      height="20"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.8294 8.9592C18.1798 8.60278 18.3034 8.07967 18.1523 7.5934C18.0008 7.10713 17.6062 6.76001 17.1215 6.68634L12.8113 6.03265C12.6278 6.00475 12.4691 5.88458 12.3871 5.71083L10.4602 1.63464C10.2438 1.17665 9.8047 0.892029 9.31529 0.892029C8.82625 0.892029 8.38711 1.17665 8.17075 1.63464L6.24344 5.7112C6.16146 5.88495 6.00248 6.00512 5.81891 6.03302L1.50877 6.68672C1.02436 6.76001 0.629414 7.10751 0.477924 7.59378C0.326791 8.08005 0.450478 8.60315 0.800864 8.95957L3.91941 12.1324C4.05237 12.2678 4.11332 12.4628 4.08195 12.6533L3.34625 17.1335C3.28102 17.5279 3.38011 17.9115 3.62463 18.214C4.0046 18.6853 4.66795 18.829 5.19834 18.538L9.05295 16.4225C9.21406 16.3344 9.41688 16.3351 9.57764 16.4225L13.4326 18.538C13.6201 18.6411 13.8201 18.6932 14.0264 18.6932C14.4032 18.6932 14.7604 18.5183 15.006 18.214C15.2508 17.9115 15.3496 17.5272 15.2843 17.1335L14.5483 12.6533C14.5169 12.4624 14.5779 12.2678 14.7108 12.1324L17.8294 8.9592Z"
                      />
                    </svg>
                    <svg
                      class="section-icon icon-plus ml-2"
                      width="20"
                      height="20"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5707 7.15065H11.8835V2.46344C11.8835 1.31187 10.9163 0.369263 9.76434 0.369263C8.61235 0.369263 7.64515 1.31187 7.64515 2.46344V7.15065H2.95795C1.80638 7.15065 0.86377 8.11784 0.86377 9.26983C0.86377 10.4218 1.80638 11.389 2.95795 11.389H7.64515V16.0762C7.64515 17.2278 8.61235 18.1704 9.76434 18.1704C10.9163 18.1704 11.8835 17.2278 11.8835 16.0762V11.389H16.5707C17.7223 11.389 18.6649 10.4218 18.6649 9.26983C18.6649 8.11784 17.7223 7.15065 16.5707 7.15065Z"
                      />
                    </svg>
                  </b-col>
                </b-row>
                <b-row>
                  <b-card-text class="f-12 px-3 pb-3">
                    {{ niche.description | truncate(250) }}
                  </b-card-text>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <NicheSection v-if="niche && nicheModal" :niche="niche" :showModalEl="nicheModal" @close-modal="hideNicheModal" />
    </div>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';
import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import NichesService from '~/services/NichesService.js';
import { debounce } from 'lodash';
import NicheSection from '~/components/NicheModal';

const accessControl = createNamespacedHelpers('accessControl');

export default {
  components: { WorkspaceHeader, NicheSection },

  data() {
    return {
      loading: true,
      currentSectionId: 0,
      nicheModal: false,
      niche: null,
      niches: {},
      search: '',
      section: '',
    };
  },
  nichesServices: null,

  apollo: {
    fetchWorkspaces: {
      query: FETCH_WORKSPACES_QUERY,
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authBusy: state => state.auth.busy,
    }),

    ...accessControl.mapGetters(['isUserReseller', 'isUserAdminInWorkspace', 'isUserClientInWorkspace']),

    filteredNiches() {
      const search = this.formatText(this.search);
      if (!search) return this.niches;
      const regex = new RegExp(`${search}`);

      return this.niches.filter(niche => regex.test(this.formatText(niche.name)));
    },
  },

  filters: {
    truncate(string, value) {
      return (string || '').substring(0, value) + '…';
    },
  },

  created() {
    this.handleNicheSearch = debounce(e => {
      this.search = e.target.value;
    }, 100);

    this.nichesServices = new NichesService();
  },
  mounted() {
    this.nichesServices.getNiches().then(data => {
      this.niches = data;
      this.loading = false;
    });
  },

  methods: {
    showNicheModal() {},
    hideNicheModal() {
      this.nicheModal = false;
    },
    // Convert text to lowercase
    formatText(text) {
      return text.toLowerCase();
    },
    handleViewSection(niche) {
      this.niche = niche;
      this.nicheModal = true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.niches__page {
  position: relative;
  height: 100%;
  &-view {
    padding-top: $navbar-height;
  }
}
.search-input {
  width: 100%;
  position: relative;

  input {
    border: 3px solid $white;
    width: 100%;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    height: 44px;
    padding: 14px 18px;
  }

  input[type='search']::-webkit-search-cancel-button {
    position: absolute;
    right: 35px;
  }

  .icon {
    position: absolute;
    text-align: right;
    right: 15px;
    top: 5px;
  }

  .icon-eye {
    position: absolute;
    text-align: right;
    right: 40px;
    top: 38px;
  }
}
.niche-image {
  display: block;
  width: 100%;
  height: 250px !important;
  border-radius: 15px;
  opacity: 1;
}

.niche-container {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 15px;
  cursor: pointer;

  .premium-niche-icon {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .niche-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    // background-color: $primary;
    border-radius: 15px;
  }

  &:hover .niche-overlay {
    opacity: 1;
  }

  &:hover .niche-image {
    opacity: 0.5;
  }

  .niche-text {
    font-size: 24px;
    color: $white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.niches-container {
  .niches-card {
    border-radius: 15px;
    background-color: $black;

    .niches-card-content {
      height: 130px;
      width: 100%;
      padding: 30px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .niche-text {
      display: none;
      font-size: 24px;
      color: $white;
      font-weight: bold;
    }

    .niche-img {
      display: block;
    }

    &:hover,
    &:active {
      background-color: $primary;

      .niche-text {
        display: block;
      }

      .niche-img {
        display: none;
      }
    }
  }

  .niche-single-card {
    background: $white;
    color: $black;
    outline: 0;
    border: 0;

    .card-body {
      padding: 10px !important;
    }

    .section-icon {
      fill: $gray-200;

      &:hover {
        fill: $yellow;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background: $primary;
      color: $white;
      outline: 0;
      border: 0;
    }
  }

  .video-container {
    position: absolute;
  }

  .video-play-btn {
    position: relative;
    top: 0%;
  }
}
</style>
