<template>
  <div class="auth-module">
    <section class="p-5">
      <div class="px-5">
        <div class="my-3">
          <h4 class="font-weight-bold">
            Sign Up
            <span class="font-weight-light">
              To Social Agency 360
            </span>
          </h4>
        </div>

        <div class="my-3">
          <!-- <SocialAuth
            :queryLoading="queryLoading"
            :fbLoading="facebookLoading"
            :twLoading="twitterLoading"
            :goLoading="googleLoading"
            @fbLogin="facebookLogin()"
            @twLogin="twitterLogin()"
            @goLogin="googleLogin()"
          /> -->
        </div>
        <div class="clear-fix py-4"></div>

        <b-form @submit.prevent="register" @keydown="form.onKeydown($event)">
          <b-row>
            <b-col>
              <b-form-group>
                <label for="first_name-field" class="f-12 text-black">First Name</label>
                <b-form-input
                  id="first_name-field"
                  class="auth-input"
                  name="first_name"
                  v-model="form.firstName"
                  type="text"
                  required
                ></b-form-input>
                <has-error :form="form" field="first_name" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="last_name-field" class="f-12 text-black">FIrst Name</label>
                <b-form-input
                  id="last_name-field"
                  class="auth-input"
                  name="last_name"
                  v-model="form.lastName"
                  type="text"
                  required
                ></b-form-input>
                <has-error :form="form" field="last_name" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group>
            <label for="email-field" class="f-12 text-dark ml-2">Work Email</label>
            <b-form-input
              id="email-field"
              class="auth-input"
              name="email"
              v-model="form.email"
              type="email"
              required
            ></b-form-input>
            <has-error :form="form" field="email" />
          </b-form-group>

          <b-form-group class="position-relative">
            <label for="password-field" class="f-12 text-dark ml-2">Password</label>
            <b-form-input
              id="password-field"
              class="auth-input"
              name="password"
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="**********"
              required
            ></b-form-input>
            <has-error :form="form" field="password" />
            <span @click="showPassword = !showPassword" class="input-addon">
              <svg-icon name="eye-alt" size="md" />
            </span>
          </b-form-group>

          <div class="mt-4">
            <div class="">
              <div>
                <input type="checkbox" id="remember" class="remember_input" v-model="terms" />
                <label for="remember">
                  I've read and agreed with
                  <router-link :to="{ name: 'reset' }">terms of service and privacy policy</router-link>
                </label>
              </div>
            </div>
            <b-button class="my-4 auth-btn font-weight-bold" type="submit" :disabled="!terms">
              <b-spinner class="mr-2" v-if="form.busy" small />
              Sign Up Now
            </b-button>
            <div class="f-12">Already a member? <router-link :to="{ name: 'signin' }">Sign In</router-link></div>
          </div>
        </b-form>
      </div>
    </section>
  </div>
</template>

<script>
import Form from 'vform';
// import SocialAuth from '~/components/SocialAuthOption';
import { SIGN_UP_MUTATION } from '~/graphql/mutations';
// import { FETCH_SSO_LINKS_QUERY } from '~/graphql/queries';

export default {
  name: 'SignUp',

  metaInfo() {
    return { title: 'Sign Up' };
  },

  // components: { SocialAuth },

  data() {
    return {
      fetchSsoLinks: {},
      facebookLoading: false,
      twitterLoading: false,
      form: new Form({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      }),
      terms: false,
      showPassword: false,
    };
  },

  // apollo: {
  //   fetchSsoLinks: {
  //     query: FETCH_SSO_LINKS_QUERY,
  //     context: {
  //       uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
  //     },
  //   },
  // },

  computed: {
    // queryLoading() {
    //   return this.$apollo.queries.fetchSsoLinks.loading;
    // },
  },

  methods: {
    // facebookLogin() {
    //   this.facebookLoading = true;
    //   window.location = this.fetchSsoLinks.facebook;
    // },

    // twitterLogin() {
    //   this.twitterLoading = true;
    //   window.location = this.fetchSsoLinks.twitter;
    // },

    // googleLogin() {
    //   this.googleLoading = true;
    //   window.location = this.fetchSsoLinks.twitter;
    // },

    async register() {
      this.form.busy = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SIGN_UP_MUTATION,
          variables: {
            ...this.form.data(),
            pc: this.$route.query.pc || null,
          },
          context: {
            uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
          },
        });
        if (data && data.signUp.code === 'WEBBY_SIGNUP') {
          // TODO: update urls
          window.location = 'https://contentburger.io/contentburger-thank-you-2';

          return;
        }
        // Save the token.
        this.$store.dispatch('auth/saveToken', { token: data.signUp.token });

        // Redirect home.
        this.$router.push({ name: 'workspaces' });
      } catch ({ graphQLErrors: errors }) {
        this.form.busy = false;
        for (let i = 0; i < errors.length; i++) {
          const errorSet = errors[i];
          this.form.errors.set(errorSet.extensions.validation);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.auth-module {
  .f-12 {
    font-size: 12px;
  }
  .text-gray {
    color: #d8f3dc;
  }
  .login-option {
    overflow: hidden;
    text-align: center;
    color: #d8f3dc;

    &:before,
    &:after {
      background-color: #b8b8b8;
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
  .auth-input {
    background: #eaffed;
    border-radius: 0.5rem !important;
    height: 40px;
    &:focus,
    &:active {
      border-color: #1b4332;
    }
  }

  .remember_input {
    display: none;
  }

  .remember_input + label:before {
    content: '\2714';
    border: 0.13em solid #52b788;
    border-radius: 50%;
    display: inline-block;
    width: 1.6em;
    height: 1.6em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
  }

  .remember_input + label:active:before {
    transform: scale(0);
  }

  input[type='checkbox']:checked + label:before {
    background-color: MediumSeaGreen;
    border-color: MediumSeaGreen;
    color: #fff;
  }

  .input-addon {
    position: absolute;
    top: 40px;
    right: 18px;
  }
  .auth-btn {
    background-color: #b7e4c7;
    border-radius: 0.9rem !important;
    color: #2d6a4f;
    padding: 12px 14px;
  }
}
</style>
