<template>
  <div class="agency-edit">
    <div class="row">
      <div class="col-md-4">
        <div class="d-flex gap-5 flex-column">
          <div :class="{ step: true, active: tab === 'agency-details-form' }">
            <div class="indicator">
              1
            </div>
            <div>
              <label>Step One</label>
              <h5>Agency Details</h5>
            </div>
          </div>

          <div :class="{ step: true, active: tab === 'agency-template-form' }">
            <div class="indicator">
              2
            </div>
            <div>
              <label>Step Two</label>
              <h5>Select Agency</h5>
            </div>
          </div>

          <div :class="{ step: true, active: tab === 'agency-business-form' }">
            <div class="indicator">
              3
            </div>
            <div>
              <label>Step Three</label>
              <h5>Business Details</h5>
            </div>
          </div>

          <div :class="{ step: true, active: tab === 'agency-preview' }">
            <div class="indicator">
              4
            </div>
            <div>
              <label>Step four</label>
              <h5>Preview & Publish</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col w-100">
        <div style="border-radius: 15px;" class="card mb-3 p-4 d-flex align-items-center" v-if="loading">
          <b-spinner label="Spinning" class="mb-2"></b-spinner> Getting templates...
        </div>
        <div class="card form">
          <transition name="fade" mode="out-in">
            <agency-details-form v-if="tab === 'agency-details-form'" />
            <agency-template-form v-else-if="tab === 'agency-template-form'" :templates="templates" />
            <agency-business-form v-else-if="tab === 'agency-business-form'" />
            <agency-preview v-else-if="tab === 'agency-preview'" :templates="templates" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgencyDetailsForm from '@/components/AgencyWizard/AgencyDetailsForm.vue';
import AgencyTemplateForm from '@/components/AgencyWizard/AgencyTemplateForm.vue';
import AgencyBusinessForm from '@/components/AgencyWizard/AgencyBusinessForm.vue';
import AgencyPreview from '@/components/AgencyWizard/AgencyPreview.vue';

export default {
  components: { AgencyPreview, AgencyBusinessForm, AgencyDetailsForm, AgencyTemplateForm },
  name: 'DFYAgencyEdit',
  computed: {
    tab() {
      return this.$route.query.tab || 'agency-details-form';
    },
  },
  data: () => ({
    templates: [],
    loading: true,
  }),

  async mounted() {
    if (!this.$route.query.tab) {
      this.$router.push({
        query: {
          tab: 'agency-details-form',
        },
      });
    }

    try {
      this.loading = true;
      const res = await fetch(process.env.VUE_APP_EDITOR_URL + '/templates.php?x-user=sa360');
      const json = await res.json();
      this.templates = Array.isArray(json) ? json : Object.values(json);
    } catch (error) {
      //
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getUpdates() {},
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.agency-edit {
  .form {
    border-radius: 15px;
  }
  .gap-5 {
    gap: 3.125rem;
  }
  .step {
    display: flex;
    align-items: center;
    gap: 15px;
    label {
      text-transform: capitalize;
      font-weight: 900;
      margin-bottom: 0;
    }
    &.active {
      .indicator {
        background: $body-color;
        color: white;
      }
    }
    .indicator {
      width: 70px;
      background: white;
      aspect-ratio: 1/1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 900;
      border-radius: 15px;
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
