<template>
  <div class="business__details">
    <b-row>
      <b-col cols="6" class="mx-auto mt-4">
        <Steps :current="current" />
        <div class="business__details-content mt-5">
          <b-form @submit.prevent="saveBusinessDetails">
            <design>
              <div v-if="current === 1">
                <template>
                  <h5 class="font-weight-bold mb-0 pb-0">
                    We can't wait to meet you.
                    <svg-icon name="wink" class="ml-2" size="sm" />
                  </h5>
                  <span class="m-0 p-0 f-12">Please fill in the details below</span>

                  <div class="form">
                    <b-form-group class="mt-3">
                      <label for="business_name-field" class="f-14">Business Name</label>
                      <b-form-input
                        id="business_name-field"
                        name="business_name"
                        v-model="business.name"
                        type="text"
                        autocomplete="off"
                        required
                        min="3"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <label for="business_address-field" class="f-14">Business Address</label>
                      <b-form-input
                        id="business_address-field"
                        class="form-input"
                        name="business_address"
                        v-model="business.address"
                        type="text"
                        autocomplete="off"
                        required
                        min="5"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <label for="business_phone-field" class="f-14">Business Contact Number</label>
                      <vue-tel-input id="business_phone-field" v-model="business.phone" v-bind="bindPhoneProps" />
                    </b-form-group>
                  </div>
                </template>
              </div>

              <div v-if="current === 2">
                <template>
                  <h5 class="font-weight-bold mb-0 pb-0">
                    We can't wait to meet you.
                    <svg-icon name="wink" class="ml-2" size="sm" />
                  </h5>
                  <span class="m-0 p-0 f-12">Please fill in the details below</span>

                  <div class="form">
                    <b-form-group class="mt-3">
                      <p class="f-14">Logo</p>
                      <b-row>
                        <b-col cols="10">
                          <b-row class="align-items-center">
                            <b-col>
                              <div
                                class="business__details-brand-logo-input"
                                :class="{
                                  'business__details--collapsed': this.business.logo,
                                }"
                              >
                                <input
                                  ref="Logo"
                                  type="file"
                                  autocomplete="off"
                                  accept="image/*"
                                  class="d-none"
                                  id="brand_logo"
                                  @change="handleFileUpload()"
                                />
                                <b-button
                                  for="brand_logo"
                                  variant="secondary"
                                  class="f-12 w-100 p-2"
                                  rounded
                                  @click="$refs.Logo.click()"
                                >
                                  <svg-icon name="download-alt" size="md" />
                                  <span v-if="business.logo">Change Logo</span>
                                  <span v-else>Upload Logo</span>
                                </b-button>
                                <b-form-text id="brand_logo-help" class="">
                                  size: 130 x 130; max: 2mb
                                </b-form-text>
                              </div>
                            </b-col>
                            <b-col>
                              <div class="business-logo-preview">
                                <div v-if="url || business.logo" class="">
                                  <b-avatar class="avatar" size="6rem" :src="url || business.logo" />
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group>
                      <label for="brand_tagline-field" class="f-14">Business Tagline</label>
                      <b-form-input
                        id="brand_tagline-field"
                        class="auth-input text-lowercase"
                        v-model="business.tagline"
                        type="text"
                        autocomplete="off"
                        @keyup="handleBusinessTaglineKeyUp"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <label for="brand_description-field" class="f-14">Business Description</label>
                      <b-form-input
                        id="brand_description-field"
                        class="auth-input"
                        v-model="business.description"
                        type="text"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </template>
              </div>
            </design>

            <div class="float-right mt-3">
              <a
                href="#no-link"
                v-if="current === 1"
                class="business__details-btn shadow font-weight-bold bg-primary text-white text-decoration-none"
                @click="goNext(current)"
              >
                Next
                <svg-icon name="arrow-right-alt" size="md" class="ml-2" />
              </a>
              <b-button
                v-if="current === 2"
                class="business__details-btn shadow font-weight-bold"
                :disabled="savingBusinessDetails"
                type="submit"
                variant="primary"
              >
                Next
                <b-spinner class="ml-2" v-if="savingBusinessDetails" small />
                <svg-icon v-else name="arrow-right-alt" size="md" class="ml-2" />
              </b-button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Form from 'vform';
import slugify from 'slugify';
import { mapState } from 'vuex';
import Steps from '~/components/Setup/Steps.vue';
import Design from '~/components/Setup/UI-design.vue';
import moment from 'moment-timezone';
import { VueTelInput } from 'vue-tel-input';
import { SAVE_BUSINESS_MUTATION, UPLOAD_WORKSPACE_LOGO_MUTATION } from '~/graphql/mutations';

import app from '~/main';
import { isString, isArray } from 'lodash';

export default {
  components: { Steps, Design, VueTelInput },

  async beforeRouteEnter(to, from, next) {
    const user = app.$store.state.auth.user;
    const randomnumber = Math.floor(Math.random() * (100 - 0 + 1)) + 0;

    if (user.status == 'invited') {
      app.$notify({
        group: 'main',
        title: 'Please Upgrade',
        type: 'native-error',
        text:
          'You do not have an active subscription. Please purchase a subscription in order to create your own business.',
      });

      return next({ name: 'workspaces', query: { rf: randomnumber } });
    }

    next();
  },

  data() {
    return {
      current: 1,
      savingBusinessDetails: false,
      value: '',
      url: null,
      brandLogoUrl: '',
      business: new Form({
        name: '',
        address: '',
        phone: '',
        logo: '',
        tagline: '',
        description: '',
        slug: '',
        logoFile: undefined,
      }),
      timezone: moment.tz.guess(),
      bindPhoneProps: {
        type: 'tel-input',
        mode: 'international',
        defaultCountry: 'US',
        disabledFetchingCountry: false,
        disabled: false,
        placeholder: 'Biz Phone',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        autocomplete: 'off',
        name: 'phone',
        wrapperClasses: 'form-input',
      },
    };
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),

    timezones() {
      const timezones = moment.tz.names();
      const timezoneNames = timezones
        .map(timezone => ({
          value: timezone,
          text: `${timezone} (GMT ${moment.tz(timezone).format('Z')})`,
        }))
        .sort();

      return [{ value: null, text: 'Select Timezone' }, ...timezoneNames];
    },
  },

  methods: {
    goNext(current) {
      if (!this.validateStep1()) return;
      const active = current + 1;
      this.current = active;
    },

    goToTab(no = 1) {
      if (no < 1) {
        this.current = 1;
      } else if (no > 2) {
        this.current = 2;
      } else {
        this.current = no;
      }
    },

    handleFileUpload() {
      const file = this.$refs.Logo.files[0];
      if (!file) return;
      if (!this.isValidFile(file)) return;

      const fileReader = new FileReader();
      fileReader.onloadend = () => (this.url = fileReader.result);
      fileReader.readAsDataURL(file);
      this.business.logoFile = file;
    },

    handleBusinessTaglineKeyUp() {
      const escaped = this.business.tagline.replace(/[&/\\#@,+()$~%.!'":*?<>{}]/g, '-');
      const tagline = slugify(escaped).toLowerCase();
      this.business.slug = tagline;
      this.business.tagline = tagline;
    },

    isValidFile(file) {
      const imageFormats = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg', 'image/webp'];

      const inValidType = !imageFormats.includes(file.type);

      if (inValidType) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Invalid file type',
          text: `Please upload a valid image`,
        });
        return false;
      }

      const size = file.size / 1000;
      if (imageFormats.includes(file.type) && size > 2048) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Image too large',
          text: 'Image size must not exceed 2MB',
        });
        return false;
      }

      return true;
    },

    // Default Workspace Logo
    async handleBrandLogoUpload() {
      if (this.brandLogoUrl) {
        return this.brandLogoUrl;
      }

      const file = this.$refs.Logo.files[0];
      if (!this.isValidFile(file)) return;

      const {
        data: { uploadWorkspaceLogo },
      } = await this.$apollo.mutate({
        mutation: UPLOAD_WORKSPACE_LOGO_MUTATION,
        variables: { logo: file },
      });

      const brandLogo = uploadWorkspaceLogo.url;
      this.brandLogoUrl = brandLogo;

      return this.brandLogoUrl;
    },

    async saveBusinessDetails() {
      try {
        if (!this.validateStep2()) return;

        this.savingBusinessDetails = true;
        const logoUrl = await this.handleBrandLogoUpload();

        const {
          data: { saveBusiness },
        } = await this.$apollo.mutate({
          mutation: SAVE_BUSINESS_MUTATION,
          variables: {
            name: this.business.name,
            address: this.business.address,
            phone: this.business.phone,
            slug: this.business.tagline,
            description: this.business.description,
            timezone: this.timezone,
            logo: logoUrl,
          },
        });

        console.log('business', saveBusiness);

        this.$notify({
          group: 'main',
          type: 'native',
          text: 'Business successfully added.',
        });

        this.savingBusinessDetails = false;
        window.location = '/teams';

        // this.$router.push({ name: 'teams' }, () => {
        //   this.savingBusinessDetails = false;
        // });
      } catch (e) {
        console.log('caught error in mutate here is ===> ', e);
        console.dir(e);

        this.showValidationErrors(e);
        this.savingBusinessDetails = false;
      }
    },

    showValidationErrors(e) {
      if (e && e.graphQLErrors && isArray(e.graphQLErrors)) {
        const error = e.graphQLErrors[0];

        if (error && error.message) {
          if (error.message === 'BusinessNameExists') {
            this.goToTab(1);

            this.$notify({
              group: 'main',
              type: 'error',
              text: 'Business name already exists... Can you try using another business name',
            });
            return;
          } else if (error.message === 'BusinessSlugExists') {
            this.goToTab(2);

            this.$notify({
              group: 'main',
              type: 'error',
              text: 'Business tagline already exists. Please choose a more uniqie tagline',
            });
            return;
          } else if (error.message === 'ErrorSavingBusiness') {
            this.goToTab(2);

            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while saving business',
            });
            return;
          }
        }
      }

      this.$notify({
        group: 'main',
        type: 'error',
        text: 'An error occurred while processing your request.',
      });
    },

    validateStep1() {
      if (!this.business.name || this.business.name.length < 3) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Business Name must be at least 3 characters',
        });

        return false;
      }
      if (!this.business.address) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please enter a business address',
        });

        return false;
      }
      if (!this.business.phone) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please enter a Phone Number',
        });

        return false;
      }
      return true;
    },

    validateStep2() {
      const file = this.$refs.Logo.files[0];
      if (!(this.brandLogoUrl || (file && this.isValidFile(file)))) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please upload a brand logo',
        });

        return false;
      }

      if (!isString(this.business.slug)) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please enter a business tagline',
        });

        return false;
      }

      if (this.business.slug) {
        const regexp = new RegExp('^[0-9A-Za-z-]+$');
        if (!regexp.test(this.business.slug)) {
          this.$notify({
            group: 'main',
            type: 'error',
            text: 'Tagline can only contain aplha numeric characters and -',
          });

          return false;
        }

        if (this.business.slug.length > 255) {
          this.$notify({
            group: 'main',
            type: 'error',
            text: 'Tagline character length must be less than or equal to 255',
          });

          return false;
        }
      }

      if (!this.business.description) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please enter a business description',
        });

        return false;
      }

      return true;
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>;
<style lang="scss">
@import '~@/scss/variables';
.business__details {
  color: #000000;
  &-content {
    background-color: transparent;
  }
  input {
    border: 1.5px solid #b7e4c7;
    border-radius: 8px;
    height: 40px;
    color: $primary;
    &:focus,
    &:active {
      border-color: $primary;
    }
  }
  .vti__input {
    border: none !important;
  }
  .vue-tel-input:focus-within,
  .vti__dropdown:hover {
    border-color: $primary !important;
    background: inherit !important;
  }
  .vue-tel-input {
    border: 1.5px solid #b7e4c7 !important;
    border-radius: 8px !important;
    color: $primary !important;
    background: $white !important;
  }
  &-btn {
    border-radius: 0.9rem !important;
    padding: 12px 14px;
  }
  .f-12 {
    font-size: 12px;
  }
  .f-14 {
    font-size: 14px;
  }
}
</style>
