<template>
  <div>
    <WorkspaceHeader />
    <div class="brand__details">
      <!--
      <TopWizard
        class="mt-4"
        :sequence="sequence"
        :currentStep="currentStep"
        :goBackText="goBackText"
        :goBackHandler="goBackHandler"
        :sidebar="false"
      >
        <template v-slot:wizard-link="{ step }">
          <a
            @click.prevent="setCurrentStep(step.number)"
            class="wiz-step-item"
            :class="{ active: currentStep === step.number }"
            href="#"
            ><span class="marker"></span> {{ step.name }}</a
          >
        </template>

        <div class="action-buttons" slot="buttons">
          <span id="next-btn-wrap" tabindex="0">
            <button @click="moveToNext" :disabled="isBrandDetailsSavinng" class="btn px-4 soft-outline topbar_btn">
              <div class="topbar_btn-inner">
                <div class="back-label">Save</div>
                <b-spinner v-if="isBrandDetailsSavinng" class="m-1" small />
                <svg-icon v-else name="check" class="m-1" />
              </div>
            </button>
          </span>
          <b-tooltip
            v-if="isBrandDetailsSavinng"
            triggers="hover"
            target="next-btn-wrap"
            variant="info"
            placement="bottom"
            custom-class="error-tip"
            title="Saving brand..."
          ></b-tooltip>
        </div>
      </TopWizard>
    -->
      <!---->
      <b-row>
        <b-col cols="6" class="mx-auto mt-3">
          <h5 class="font-weight-bold text-center mb-0 pt-4 pb-0">
            Create a Brand
          </h5>
          <div class="m-0 p-0 f-12 text-center">Please fill in the details below</div>
          <div class="brand__details-content my-3">
            <b-form @submit.prevent="saveBrandDetails">
              <design>
                <template>
                  <div class="form">
                    <b-form-group class="mt-3">
                      <p class="f-14">Logo</p>
                      <b-row>
                        <b-col cols="10">
                          <b-row class="align-items-center">
                            <b-col>
                              <div class="brand__details-brand-logo-input">
                                <input
                                  ref="Logo"
                                  type="file"
                                  autocomplete="off"
                                  accept="image/*"
                                  class="d-none"
                                  id="brand_logo"
                                  @change="handleLogoUpload"
                                />
                                <b-button
                                  for="brand_logo"
                                  variant="secondary"
                                  class="f-12 w-100 p-2"
                                  rounded
                                  @click="$refs.Logo.click()"
                                >
                                  <svg-icon name="download-alt" size="md" />
                                  <span v-if="brand.logo">Change Logo</span>
                                  <span v-else>Upload Logo</span>
                                </b-button>
                                <b-form-text id="brand_logo-help" class="">
                                  size: 130 x 130; max: 2mb
                                </b-form-text>
                              </div>
                            </b-col>
                            <b-col>
                              <div class="brand-logo-preview">
                                <div v-if="uploadingLogo">
                                  <b-spinner variant="primary" label="Spinning" style="width: 3rem; height: 3rem;" />
                                </div>
                                <div v-else-if="url || brand.logo" class="">
                                  <b-avatar class="avatar" size="6rem" :src="url" />
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group>
                      <label for="brand_tagline-field" class="f-14">Brand Name</label>
                      <b-form-input
                        id="brand_tagline-field"
                        class="auth-input text-lowercase"
                        v-model="brand.name"
                        type="text"
                        autocomplete="off"
                        @keyup="handleBrandNameKeyUp"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <label for="brand_slug-field" class="f-14">Brand Slug</label>
                      <b-form-input
                        id="brand_slug-field"
                        class="auth-input"
                        v-model="brand.slug"
                        type="text"
                        autocomplete="off"
                        placeholder="e.g. mybrand"
                        @keyup="handleBrandSlugKeyup"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </template>
              </design>

              <div class="float-right mt-3">
                <b-button
                  class="brand__details-btn shadow font-weight-bold"
                  :disabled="isBrandDetailsSavinng"
                  type="submit"
                  variant="primary"
                >
                  Save
                  <b-spinner class="ml-2" v-if="isBrandDetailsSavinng" small />
                  <svg-icon v-else name="check" size="md" class="ml-2" />
                </b-button>
              </div>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import slugify from 'slugify';
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import Design from '~/components/Setup/UI-design.vue';
import TopWizard from '~/components/TopWizard';
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';

import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import { UPLOAD_WORKSPACE_LOGO_MUTATION, SAVE_WORKSPACE_MUTATION } from '~/graphql/mutations';

import app from '~/main';
import { can } from '~/utils/helpers';

export default {
  components: { Design, TopWizard, WorkspaceHeader },

  async beforeRouteEnter(to, from, next) {
    const user = app.$store.state.auth.user;
    const sub = user ? user.subscription : null;
    const randomnumber = Math.floor(Math.random() * (100 - 0 + 1)) + 0;

    if (user.status == 'invited') {
      app.$notify({
        group: 'main',
        title: 'Please Upgrade',
        type: 'native-error',
        text:
          'You do not have an active subscription. Please purchase a subscription in order to create your own workspace.',
      });

      return next({ name: 'workspaces', query: { rf: randomnumber } });
    }

    const canView = user && can(user, 'bypass-subscription');
    const limit = parseInt(sub ? sub.plan.limits.workspaces : '');
    const limitCount = isNaN(limit) ? Infinity : limit;
    const usedCount = parseInt(sub ? sub.limitsUsage.workspaces : '');

    const hasExceeded = sub && usedCount >= limitCount;

    if (!canView && hasExceeded) {
      app.$notify({
        group: 'main',
        title: 'Please Upgrade',
        type: 'native-error',
        text:
          'You have reached the maximum number of workspaces allowed in your current plan. Please upgrade to create more workspaces.',
      });

      return next({ name: 'workspaces', query: { rf: randomnumber } });
    }

    next();
  },

  data() {
    return {
      isBrandDetailsSavinng: false,
      uploadingLogo: false,
      timezone: moment.tz.guess(),
      isSlugDirty: false,
      invalidSlug: null,
      currentStep: 2,
      url: null,
      brandName: '',

      brand: {
        name: '',
        description: '',
        logo: '',
      },
    };
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),

    goBackText() {
      return this.currentStep === 1 ? 'Dashboard' : 'Go Back';
    },

    sequence() {
      return [
        {
          number: 1,
          name: 'Brands',
          to: '',
        },
        {
          number: 2,
          name: 'Add Brand',
          to: '',
        },
      ];
    },

    isLastStep() {
      return this.currentStep === this.sequence.length;
    },

    timezones() {
      const timezones = moment.tz.names();
      const timezoneNames = timezones
        .map(timezone => ({
          value: timezone,
          text: `${timezone} (GMT ${moment.tz(timezone).format('Z')})`,
        }))
        .sort();

      return [{ value: null, text: 'Select Timezone' }, ...timezoneNames];
    },
  },

  created() {},

  methods: {
    close() {
      this.$router.push({ name: 'workspaces' });
    },

    goBackHandler() {
      this.close();
    },

    /**
     * Moves to the next step by incrementing currentStep by 1
     *
     * @return {void}
     */
    async moveToNext() {
      if (this.isLastStep) {
        return this.saveBrandDetails();
      }

      this.setCurrentStep(this.currentStep + 1);
      window.scrollTo(0, 0);
    },

    /**
     * Sets the current step
     *
     * @param  {Int} step A step number to move to
     * @return {void}
     */
    setCurrentStep(stepNumber) {
      if (stepNumber === 1) {
        this.close();
      }
    },

    handleLogoUpload() {
      const file = this.$refs.Logo.files[0];
      if (!file) return;
      this.uploadingLogo = true;
      const fileReader = new FileReader();
      fileReader.onloadend = () => (this.url = fileReader.result);
      fileReader.readAsDataURL(file);
      if (!this.isValidFile(file)) return;

      this.$apollo
        .mutate({
          mutation: UPLOAD_WORKSPACE_LOGO_MUTATION,
          variables: { logo: file },
        })
        .then(({ data: { uploadWorkspaceLogo } }) => {
          this.uploadingLogo = false;
          this.brand.logo = uploadWorkspaceLogo.url;
          this.url = uploadWorkspaceLogo.url;
        })
        .catch(error => {
          this.uploadingLogo = false;
          this.showValidationErrors(error);
          this.url = null;
        });
    },

    handleBrandNameKeyUp() {
      if (!this.isSlugDirty) {
        const escaped = this.brand.name.replace(/[&/\\#@,+()$~%.!'":*?<>{}]/g, '-');
        this.brand.slug = slugify(escaped).toLowerCase();
      }
    },

    handleBrandSlugKeyup() {
      this.isSlugDirty = this.brand.slug !== slugify(this.brand.name);
      const regexp = new RegExp('^[0-9A-Za-z-]+$');
      if (this.brand.slug && !regexp.test(this.brand.slug)) {
        this.invalidSlug = false;
      } else {
        this.invalidSlug = null;
      }
    },

    isValidFile(file) {
      const imageFormats = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg', 'image/webp'];

      const inValidType = !imageFormats.includes(file.type);

      if (inValidType) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Invalid file type',
          text: 'You can only upload an image',
        });
        return false;
      }

      const size = file.size / 1000;
      if (imageFormats.includes(file.type) && size > 2048) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Image too large',
          text: 'Image size must not exceed 2MB',
        });
        return false;
      }

      return true;
    },

    saveBrandDetails() {
      if (!this.validateForm()) return;

      this.isBrandDetailsSavinng = true;
      this.$apollo
        .mutate({
          mutation: SAVE_WORKSPACE_MUTATION,
          variables: {
            name: this.brand.name,
            slug: this.brand.slug,
            logo: this.brand.logo,
            timezone: this.timezone,
          },
          update: (store, { data: { saveWorkspace } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              data.fetchWorkspaces.push(saveWorkspace);

              // Write our data back to the cache.
              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Workspace successfully added.',
          });

          // const usedCount = parseInt(this.user.subscription.limitsUsage.workspaces);
          // this.$store.dispatch('auth/updateUser', {
          //   user: {
          //     ...this.user,
          //     subscription: {
          //       ...this.user.subscription,
          //       limitsUsage: {
          //         ...this.user.subscription.limitsUsage,
          //         workspaces: usedCount + 1,
          //       },
          //     },
          //   },
          // });

          this.$router.push({ name: 'workspaces' }, () => (this.isBrandDetailsSavinng = false));
        })
        .catch(error => {
          this.showValidationErrors(error);

          this.isBrandDetailsSavinng = false;
        });
    },

    showValidationErrors({ graphQLErrors }) {
      const validations = graphQLErrors.filter(err => err.message == 'validation');
      const slugError = graphQLErrors.find(err => err.message == 'SlugExists');

      if (slugError) {
        this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'You already have a brand with this slug. Try a different slug',
        });
      } else if (validations.length) {
        validations.forEach(err => {
          const errors = err.extensions.validation;
          for (let key in errors) {
            const fieldErrors = errors[key];

            this.$notify({
              group: 'main',
              type: 'error',
              title: `Invalid ${key}`,
              text: fieldErrors.join('<br />'),
            });
          }
        });
      } else {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'An error occurred while processing your request.',
        });
      }
    },

    validateForm() {
      if (!this.brand.logo) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please upload a brand logo',
        });

        return false;
      }
      if (!this.brand.name) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please enter a brand name',
        });

        return false;
      }
      if (!this.brand.slug) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please enter a brand slug',
        });

        return false;
      }
      const regexp = new RegExp('^[0-9A-Za-z-]+$');
      if (this.brand.slug && !regexp.test(this.brand.slug)) {
        return;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.brand__details {
  padding-top: ($topbar-height + 20px);
  color: #000000;
  &-content {
    background-color: transparent;
  }
  input {
    border: 1.5px solid #b7e4c7;
    border-radius: 8px;
    height: 40px;
    &:focus,
    &:active {
      border-color: $primary;
    }
  }
  &-btn {
    border-radius: 0.9rem !important;
    padding: 10px 20px;
  }
  &-brand-logo-upload {
    cursor: pointer;
    width: 80%;
    background-color: #e4e4e4;
    border: 2px solid #b7e4c7;
    border-radius: 15px;
    color: #40916c;
    font-weight: bold;
    padding: 10px 15px;
    transition: all 0.2s ease-in !important;
  }
  &--collapsed {
    display: none;
  }
  &-brand-logo-preview {
    width: 130px;
    height: 130px;
    margin: 0 auto;
    padding: 10px;
    .picture {
      width: 100%;
      height: 100%;
    }
  }
  .f-12 {
    font-size: 12px;
  }
  .f-14 {
    font-size: 14px;
  }
}
</style>
