<template>
  <div class="" :class="{ scroll_bg: isDashboard }">
    <div class="topbar__wrap flex flex-row items-center">
      <div>
        <div col cols="2" class="d-md-flex align-center justify-content-end">
          <div v-if="backButton" @click="goBackHandler" class="btn px-2 soft-outline topbar_btn">
            <div class="topbar_btn-inner flex flex-row">
              <svg-icon name="rounded-back-arrow" class="m-1" />
              <div class="back-label text-nowrap">{{ goBackText }}</div>
            </div>
          </div>
        </div>

        <div v-if="sequence" class="flex flex-row bg-[#95d5b2] wiz-steps overflow-hidden rounded-md">
          <template v-for="(step, i) in sequence">
            <slot name="wizard-link" :step="step">
              <a
                :key="`st${i + 1}`"
                :href="resolveRoute(step.to)"
                class="wiz-step-item py-3 px-2"
                :class="{ active: currentStep == step.number, disabled: step.disabled, 'py-3 px-2': true }"
                @click="handleWizardRouteClick(step, $event)"
              >
                {{ step.name }}
              </a>
            </slot>
          </template>
        </div>

        <div class="d-flex align-center">
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wizard',

  props: {
    position: {
      type: String,
      default: 'top',
    },

    sidebar: {
      type: Boolean,
      default: true,
    },

    backButton: {
      type: Boolean,
      default: true,
    },

    goBackText: {
      type: String,
      default: 'Go Back',
    },

    sequence: {
      type: Array,
    },
    currentStep: {
      number: Number,
    },

    goBackHandler: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    isDashboard() {
      return this.$route.name === 'dashboard';
    },
    resolveRoute() {
      return route => {
        const resolved = this.$router.resolve(route);
        return resolved ? resolved.href : '#';
      };
    },
  },

  methods: {
    handleWizardRouteClick(step, event) {
      if (step.disabled) {
        event.stopImmediatePropagation();
        event.preventDefault();
        return;
      }

      if (step.to) {
        this.$router.push(step.to);
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.scroll_bg {
  background: $light;
}

.topbar__wrap {
  width: 70%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > div {
    display: inline-flex;
    align-items: center;
    gap: 1rem;

    & > * {
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
  // justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 14px;

  .wiz-steps {
    width: max-content;
    background: #95d5b2;
    border-radius: 0.5rem;

    overflow: hidden;
    .wiz-step-item {
      position: relative;
      font-size: 12px;
      padding: 10px 20px;
      // margin: 0 2px;
      white-space: nowrap;
      &:hover,
      &:active,
      &.active {
        text-decoration: none;
        border-radius: 0px !important;
        font-weight: 700;
        background-color: $primary;
        color: white;
      }

      &.disabled {
        cursor: not-allowed;
        pointer-events: initial;
        background-color: transparent;
        color: gray;

        &:hover {
          background-color: transparent;
          color: gray;
        }
      }
      // letter-spacing: 0.2px;
    }
  }
}
</style>
