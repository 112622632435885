<template>
  <div class="business__details-form">
    <span class="icon-top">
      <img src="@/assets/icons/wave-3.svg" alt="icon" />
    </span>
    <span class="icon-bottom-1">
      <img src="@/assets/icons/wave-2.svg" alt="icon" />
    </span>
    <span class="icon-bottom-2">
      <img src="@/assets/icons/wave-1.svg" alt="icon" />
    </span>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import '~@/scss/variables';
.business__details {
  &-form {
    background-color: $gray;
    border-radius: 15px;
    width: 100%;
    padding: 30px 50px 50px 50px;
    position: relative;
    z-index: 2;
    .icon-top {
      position: absolute;
      top: -20px;
      right: -15px;
    }
    .icon-bottom-1 {
      position: absolute;
      bottom: 50px;
      left: -35px;
    }
    .icon-bottom-2 {
      position: absolute;
      bottom: -30px;
      left: -35px;
      z-index: 1;
    }
  }
}
</style>
