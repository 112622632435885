export default class NichesService {
  getNiches() {
    return fetch('data/niches.json')
      .then(res => res.json())
      .then(d => d.data);
  }
  getNiche(params) {
    const queryParams = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    return queryParams;
  }
}
