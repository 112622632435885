<template>
  <div class="p-4">
    <h5 class="text-bold mb-3">Agency Website Details</h5>
    <b-form-group id="name" label="Agency Name:" label-for="input-1">
      <b-form-input id="input-1" v-model="form.name" type="text" placeholder="Enter website name"></b-form-input>
    </b-form-group>

    <b-form-group id="support-email " label="Support Email:" label-for="input-2">
      <b-form-input id="input-2" v-model="form.support_email" placeholder="Enter admin email"></b-form-input>
    </b-form-group>

    <b-form-group id="custom-javascript " label="Custom Javascript:" label-for="input-3">
      <b-form-textarea
        v-model="form.custom_domain"
        id="input-3"
        placeholder="<script>...</script>"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
    </b-form-group>
    <b-row cols-md="2">
      <b-col>
        <b-form-group id="favicon" description="32 X 32 size image is preferred" label="Favicon:" label-for="input-4">
          <b-form-file id="input-4" v-model="form.favicon" accept=".jpg, .png, .ico, .svg">
            <template slot="file-name" slot-scope="{ names }">
              <b-badge variant="dark">{{ names[0] }}</b-badge>
              <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                + {{ names.length - 1 }} More files
              </b-badge>
            </template>
          </b-form-file>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="logo" description="180 X 34 size image is preferred" label="App Logo:" label-for="input-5">
          <b-form-file id="input-5" v-model="form.logo" accept=".jpg, .png, .ico, .svg, .gif">
            <template slot="file-name" slot-scope="{ names }">
              <b-badge variant="dark">{{ names[0] }}</b-badge>
              <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                + {{ names.length - 1 }} More files
              </b-badge>
            </template>
          </b-form-file>
        </b-form-group>
      </b-col>
    </b-row>

    <b-button type="submit" variant="primary" @click="saveAndContinue"
      >Save & Continue <b-spinner v-if="loading" small type="grow"></b-spinner
    ></b-button>
  </div>
</template>

<script>
import Form from 'vform';
import { UPDATE_DFY_WEBSITE_MUTATION } from '~/graphql/mutations';

export default {
  name: 'AgencyDetailsForm',
  data: () => ({
    agency: null,
    logo: null,
    favicon: null,
    loading: false,
    form: new Form({
      custom_domain: '',
      name: '',
      email: '',
      logo: null,
      favicon: null,
      id: null,
    }),
  }),
  mounted() {
    this.getAgency();
    this.$watch('logo', () => {
      const file = this.file.logo;
      if (!file) this.form.logo = null;

      const fileReader = new FileReader();
      fileReader.onloadend = () => (this.url = fileReader.result);
      fileReader.readAsDataURL(file);

      this.form.logo = file;
    });
    this.$watch('favicon', () => {
      const file = this.file.favicon;
      if (!file) this.form.favicon = null;

      const fileReader = new FileReader();
      fileReader.onloadend = () => (this.url = fileReader.result);
      fileReader.readAsDataURL(file);

      this.form.favicon = file;
    });
  },
  methods: {
    async getAgency() {
      try {
        const url = `${process.env.VUE_APP_API_ROOT}/api/agencies/${this.$route.params.id}`;
        const data = await fetch(url);
        const response = await data.json();
        if (response?.agency) {
          this.updateForm(response?.agency);
        }
      } catch (error) {
        console.error(error);
      }
    },

    updateForm(form) {
      this.form.name = form.name;
      this.form.id = +this.$route.params.id;
      this.form.custom_domain = form.custom_domain;
      this.form.support_email = form.support_email;
    },
    async updateAgency() {
      try {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: UPDATE_DFY_WEBSITE_MUTATION,
          variables: { ...this.form },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    saveAndContinue() {
      // save the form
      this.updateAgency().then(() => {
        this.$router.push({
          query: {
            tab: 'agency-template-form',
          },
        });
      });
    },
  },
};
</script>

<style></style>
