<template>
  <div class="pb-4">
    <WorkspaceHeader />
    <b-container class="pt-5 max-w-75">
      <div class="shadow my-5 p-5">
        <b-form @submit.prevent="handleFormSubmit">
          <b-row class="align-items-center">
            <b-col cols="4">
              <b-form-group id="keyword" label="Keyword:" label-for="keyword">
                <b-form-input
                  id="keyword"
                  v-model="form.keyword"
                  placeholder="Enter a Keyword"
                  type="text"
                  required
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group id="location" label="Location:" label-for="locationInput">
                <b-form-input
                  id="locationInput"
                  v-model="form.location"
                  placeholder="Enter Location"
                  type="text"
                  autocomplete="off"
                  required
                  ref="location_autocomplete"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="2">
              <b-form-group id="radius" label="Radius:" label-for="radius">
                <b-form-input
                  id="radius"
                  v-model="form.radius"
                  placeholder="Enter a Radius"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-button type="submit" variant="primary" class="mt-3 px-4 py-2 rounded-pill" :disabled="loading">
                <b-spinner v-if="loading" class="mr-1" small />
                <svg-icon v-else name="search" class="mr-1" size="sm" />
                Search
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <div class="py-3 mt-3">
          <b-table fixed striped hover :items="leads" :busy="loading" :fields="fields" responsive="md">
            <template #table-busy>
              <div class="text-center text-primary p-5">
                <b-spinner class="align-middle px-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(rating)="data">
              <b-icon
                v-for="index in 5"
                :key="index"
                :icon="index <= data.value ? 'star-fill' : 'star'"
                :variant="index <= data.value ? 'warning' : 'gray'"
              ></b-icon>
            </template>

            <template #cell(phone)="data">
              <b-link v-if="data.value" :href="`tel:${data.value}`" target="_blank">
                <small>{{ data.value }}</small>
                <b-icon icon="telephone-outbound-fill" class="px-2" />
              </b-link>
            </template>

            <template #cell(website)="data">
              <b-link v-if="data.value" :href="data.value" target="_blank">
                <small>Visit</small>
                <b-icon icon="arrow-up-right-circle-fill" class="px-2" />
              </b-link>
            </template>
          </b-table>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { Form } from 'vform';
import { mapState } from 'vuex';
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';
import { LEAD_FINDER_QUERY } from '~/graphql/queries';

export default {
  components: {
    WorkspaceHeader,
  },

  data() {
    return {
      leads: [],
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'rating',
          sortable: true,
        },
        {
          key: 'address',
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
        },
        {
          key: 'website',
          sortable: true,
        },
      ],
      loading: false,
      form: new Form({
        location: '',
        latitude: '',
        longitude: '',
        keyword: '',
        radius: 1000,
      }),
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authBusy: state => state.auth.busy,
      showTutorialModal: state => state.auth.user.options.onboarding.homepage,
    }),
  },

  mounted() {
    this.initAutocomplete();
  },

  methods: {
    initAutocomplete() {
      const locationInput = document.getElementById('locationInput');

      const autocomplete = new google.maps.places.Autocomplete(locationInput, {
        types: ['geocode'],
      });

      // Listen for the place changed event
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        // Extract latitude and longitude
        const location = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };

        // Update data properties
        this.form.location = place.formatted_address;
        this.form.latitude = location.latitude;
        this.form.longitude = location.longitude;
      });
    },

    handleFormSubmit() {
      if (!this.form.location || this.form.latitude || this.form.longitude) {
        this.form.errors.set('location', ['Please Select a location']);
      }
      if (!this.form.keyword) {
        this.form.errors.set('keyword', ['Please add a keyword']);
      }

      this.loading = true;

      this.$apollo
        .mutate({
          mutation: LEAD_FINDER_QUERY,
          variables: {
            ...this.form.data(),
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.leads = data.leadFinderQuery;
        })
        .catch(error => {
          this.loading = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: error.message() ?? 'An error occurred while processing your request.',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
</style>
