<template>
  <b-container class="sso-wrapper">
    <b-row>
      <b-col offset-md="3" md="6">
        <div class="text-center mb-4 mt-5">
          <!-- TODO: update logo -->
          <img src="~@/assets/images/contentburger-logo-colored.png" height="45" alt="SocialMediaAgency360" />
        </div>
        <div class="bg-white p-5 mb-5 rounded shadow">
          <div class="text-center mb-3">
            <h5 class="font-weight-bold mb-3">Welcome! You are just one step away!</h5>
            <p class="m-0">To keep your account secured and fully accessible,</p>
            <p class="m-0">Please add your email address and set a password.</p>
          </div>

          <b-form @submit.prevent="register" @keydown="form.onKeydown($event)">
            <b-form-group class="mb-4">
              <b-form-input
                name="email"
                v-model="form.email"
                placeholder="Your email address"
                type="email"
                required
                :readonly="hasEmail"
              ></b-form-input>
              <has-error :form="form" field="email" />
            </b-form-group>

            <b-form-group class="mb-4">
              <b-form-input
                name="password"
                v-model="form.password"
                placeholder="Password"
                type="password"
                required
              ></b-form-input>
              <has-error :form="form" field="password" />
            </b-form-group>

            <b-button class="py-3 font-weight-bold text-uppercase" variant="primary" type="submit" block>
              <b-spinner class="mr-2" v-if="form.busy" small />
              Continue
            </b-button>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Form from 'vform';

import app from '~/main';
import { SIGN_UP_MUTATION, SSO_SIGN_IN_MUTATION } from '~/graphql/mutations';

export default {
  name: 'sso',

  async beforeRouteEnter(to, from, next) {
    const { id, type, email } = to.query;

    await app.$apollo
      .mutate({
        mutation: SSO_SIGN_IN_MUTATION,
        context: {
          uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
        },
        variables: {
          id,
          type,
          email,
        },
      })
      .then(({ data }) => {
        if (data.token) {
          app.$store.dispatch('auth/saveToken', { token: data.token });
          next({ name: 'workspaces' });
        }
      })
      .catch(({ graphQLErrors: errors }) => {
        const notExits = errors.find(error => error.message === 'AccountDoesNotExist');
        if (notExits) {
          // Continue to show this page
          next();
        } else {
          app.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });

          next({ name: 'signin' });
        }
      });
  },

  data() {
    return {
      form: new Form({
        email: this.$route.query.email || '',
        password: '',
      }),
    };
  },

  computed: {
    hasEmail() {
      return !!this.$route.query.email;
    },
  },

  methods: {
    async register() {
      this.form.busy = true;
      try {
        const { id, type, first_name, last_name } = this.$route.query;

        const { data } = await this.$apollo.mutate({
          mutation: SIGN_UP_MUTATION,
          variables: {
            id,
            type,
            firstName: first_name,
            lastName: last_name,
            email: this.form.email,
            password: this.form.password,
          },
          context: {
            uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
          },
        });
        // // Save the token.
        this.$store.dispatch('auth/saveToken', { token: data.token });

        // Redirect home.
        this.$router.push({ name: 'workspaces' });
      } catch ({ graphQLErrors: errors }) {
        this.form.busy = false;
        for (let i = 0; i < errors.length; i++) {
          const errorSet = errors[i];
          this.form.errors.set(errorSet.extensions.validation);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.sso-wrapper {
  .form-control {
    height: calc(2em + 0.75rem + 5px);
    border-width: 2px;
    border-color: $gray-200;

    &[readonly] {
      background-color: $white;
      opacity: 0.8;
    }
  }
}
</style>
