<template>
  <div>
    <div class="page__content">
      <b-row>
        <b-col cols="6" class="mx-auto mt-5">
          <Steps :current="current" />
          <div class="page__content-items my-5">
            <design>
              <template>
                <h5 class="font-weight-bold mb-0 pb-0">
                  Team Members
                  <svg-icon name="wink" class="ml-2" size="sm" />
                </h5>

                <div class="team__members py-4">
                  <div v-if="$apollo.queries.fetchWorkspaces.loading" class="text-center">
                    <spinner size="3" />
                  </div>
                  <div v-else>
                    <template v-if="workspaces.length">
                      <div class="team__members-list">
                        <b-row cols="2" class="team__members-item" v-for="(member, i) in members" :key="i">
                          <b-col class="d-flex align-items-center data">
                            <b-avatar class="mr-2 avatar" :src="member.photo" :alt="`${member.fullName}`" />
                            <div>
                              <div class="name">{{ member.fullName }}</div>
                              <span class="role">{{ member.role }}</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="d-flex align-items-center email">
                              <span class="mx-2">
                                <svg-icon name="mail" class="text-secondary" />
                              </span>
                              {{ member.email }}
                            </div>
                          </b-col>
                          <b-button
                            v-b-tooltip.hover
                            variant="clear"
                            class="remove_icon px-0"
                            title="Remove team member from all workspaces"
                            @click="invokeRemoveMember(member)"
                          >
                            <svg-icon name="close" size="sm" />
                          </b-button>
                        </b-row>
                      </div>
                    </template>
                    <div class="empty">
                      <b-button class="team__members-btn shadow" @click="handleAddMember">
                        Add Member
                      </b-button>
                    </div>
                  </div>
                </div>
              </template>
            </design>

            <div class="float-right mt-3 mb-5">
              <b-button
                variant="primary"
                class="page__content-btn shadow font-weight-bold"
                type="submit"
                @click="$router.push('/workspaces')"
              >
                Next Step
                <svg-icon name="arrow-right-alt" size="md" class="ml-2" />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal id="AddTeamMemberModal" modal-class="add_member_modal" hide-header hide-footer centered size="lg">
      <template v-slot:default="{ hide }">
        <b-button variant="link" class="close-btn" @click="hide()"> <svg-icon name="close" size="md" /> </b-button>
        <div class="p-4">
          <h5 class="font-weight-bold mb-0 pb-0">
            Add Team Members
            <svg-icon name="wink" class="ml-2" size="sm" />
          </h5>
          <span class="m-0 p-0 f-12">Please fill in the details below</span>

          <b-form class="team__members-add" @submit.prevent="addTeamMember" @keydown="form.onKeydown($event)">
            <b-row class="mt-3">
              <b-col>
                <b-form-group>
                  <label for="first_name-field" class="f-14">First Name</label>
                  <b-form-input
                    id="first_name-field"
                    v-model="form.firstName"
                    type="text"
                    autocomplete="off"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="last_name-field" class="f-14">Last Name</label>
                  <b-form-input
                    id="last_name-field"
                    v-model="form.lastName"
                    type="text"
                    autocomplete="off"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-1">
              <b-col>
                <b-form-group class="">
                  <label for="email-field" class="f-14">Email Address</label>
                  <b-form-input
                    id="email-field"
                    v-model="form.email"
                    type="email"
                    autocomplete="off"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="my-1">
              <b-col>
                <b-form-group>
                  <label for="type-field" class="f-14">User Type</label>
                  <b-form-select v-model="form.type" id="user-type" size="sm" value="admin">
                    <option value="team">Team</option> <option value="client">Client</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="role-field" class="f-14">User Role</label>
                  <b-form-select v-model="form.role" id="member-role" size="sm" value="admin">
                    <option value="admin">Administrator</option> <option value="collaborator">Collaborator</option>
                    <option value="approver">Approver</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label>Select Brand(s)</label>
                <p class="label-desc">Select the brand to add your team member into.</p>
                <b-card border-variant="light" class="">
                  <template #header>
                    <b-form-checkbox
                      v-model="allSelected"
                      :indeterminate="indeterminate"
                      aria-describedby="workspace"
                      aria-controls="workspace"
                      @change="toggleAll"
                    >
                      {{ allSelected ? 'Un-select All' : 'Select All' }}
                    </b-form-checkbox>
                  </template>
                  <b-card-text>
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-checkbox
                        v-for="workspace in workspaces"
                        v-model="selectedWorkspace"
                        :key="workspace.id"
                        :value="workspace.id"
                        :aria-describedby="ariaDescribedby"
                        name="workspaces"
                      >
                        <div class="d-flex">
                          <b-avatar class="mr-1 avatar" size="2rem" :src="workspace.logo" :alt="`${workspace.name}`" />
                          <p class="mt-1">{{ workspace.name }}</p>
                        </div>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>

            <b-button type="submit" class="team__members-btn shadow mt-4" :disabled="addingMember">
              <b-spinner v-if="addingMember" class="mr-1" small></b-spinner>
              Save
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="RemoveTeamMemberModal"
      modal-class="remove_member_modal"
      content-class="text-center"
      body-class="p-5"
      hide-header
      hide-footer
      centered
      @hidden="onHideRemoveTeamMembereModal"
    >
      <template v-slot:default="{ hide }">
        <b-button variant="link" class="close-btn" @click="hide()"> <svg-icon name="close" /> </b-button>
        <template v-if="!removingMember">
          <p class="m-1">By performing this action, your team member will be removed from all of the workspaces.</p>
          <div class="mt-4">
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="removeTeamMember">Continue</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Form from 'vform';
import Swal from 'sweetalert2';
import { mapState, createNamespacedHelpers } from 'vuex';
import { uniqBy } from 'lodash';
import Steps from '~/components/Setup/Steps.vue';
import Design from '~/components/Setup/UI-design.vue';
import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import {
  ADD_TEAM_MEMBER_MUTATION,
  CHANGE_TEAM_MEMBER_ROLE_MUTATION,
  REMOVE_TEAM_MEMBER_MUTATION,
} from '~/graphql/mutations';

const accessControlNamespace = createNamespacedHelpers('accessControl');

export default {
  components: { Steps, Design },

  data() {
    return {
      current: 3,
      fetchWorkspaces: [],
      addingMember: false,
      removingMember: false,
      memberInvokedForRemoval: null,

      selectedWorkspace: [],
      allSelected: false,
      indeterminate: false,

      form: new Form({
        firstName: '',
        lastName: '',
        email: '',
        role: 'admin',
        type: 'team',
        workspaces: this.selectedWorkspace,
      }),
    };
  },

  apollo: {
    fetchWorkspaces: {
      query: FETCH_WORKSPACES_QUERY,
      result({ data, loading }) {
        if (!loading && data.fetchWorkspaces) {
          const permittedWorkspaces = data.fetchWorkspaces.filter(workspace =>
            this.isUserAdminInWorkspace(workspace.id),
          );
          this.form.workspaces = permittedWorkspaces.map(w => w.id);
          return permittedWorkspaces;
        }

        return [];
      },
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    ...accessControlNamespace.mapGetters(['isUserAdminInWorkspace']),

    members() {
      const members = this.workspaces.reduce((all, item) => {
        return [...all, ...item.members];
      }, []);
      return uniqBy(members, 'email');
    },
    workspaces() {
      return this.fetchWorkspaces.filter(workspace => {
        const isAdmin = this.isUserAdminInWorkspace(workspace.id);
        return isAdmin;
      });
    },
  },

  created() {},

  watch: {
    selectedWorkspace(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.workspaces.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
    // workspaces: {
    //   handler() {
    //     const hasLoadedSmartQuery = hasIn(this, '$apollo.queries.fetchWorkspaces.loading', false);
    //     const isSmartQueryLoaded = !get(this, '$apollo.queries.fetchWorkspaces.loading', true);

    //     if (hasLoadedSmartQuery && isSmartQueryLoaded && isArray(this.fetchWorkspaces)) {
    //       if (!this.fetchWorkspaces.length) {
    //         Swal.fire({
    //           type: 'error',
    //           title: 'Empty Workspaces Data',
    //           text:
    //             'Ooops... you dont have any workspace to manage. Create a workspace or subscribe to gain permission to create one',
    //         });
    //       }
    //     }
    //   },
    //   immediate: true,
    // },
    'form.type': {
      handler(type) {
        if (type === 'client') {
          this.form.role = 'admin';
        }
      },
      immediate: true,
    },
  },

  methods: {
    toggleAll(checked) {
      this.selectedWorkspace = checked ? this.workspaces.map(w => w.id) : [];
    },

    getMemberRoleInWorkspace(userId, workspace) {
      const member = workspace.members.find(m => m.user.id == userId);

      return member ? member.role : 'none';
    },

    changeMemberRoleInWorkspace(role, userId, workspace) {
      this.$apollo
        .mutate({
          mutation: CHANGE_TEAM_MEMBER_ROLE_MUTATION,
          variables: {
            workspaceId: workspace.id,
            userId,
            role,
          },
          update: (store, { data: { changeTeamMemberRole } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              const updatedWorkspace = data.workspaces.find(w => w.id === workspace.id);

              if (updatedWorkspace) {
                const memberIndex = workspace.members.findIndex(m => m.user.id === userId);

                if (memberIndex !== -1) {
                  if (role === 'none') {
                    updatedWorkspace.members.splice(memberIndex, 1);
                  } else {
                    updatedWorkspace.members.splice(memberIndex, 1, changeTeamMemberRole);
                  }
                } else {
                  updatedWorkspace.members.push(changeTeamMemberRole);
                }
              }
              // Write our data back to the cache.
              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
              // console.log(e);
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Success!',
            text: 'Team member role has been changed.',
          });
        });
    },

    handleAddMember() {
      this.$bvModal.show('AddTeamMemberModal');
    },

    addTeamMember() {
      const user = this.user;
      const sub = user ? user.subscription : null;

      const limit = parseInt(sub ? sub.plan.limits.members : '');
      const limitCount = isNaN(limit) ? Infinity : limit;

      if (this.fetchWorkspaces.length && !this.workspaces.length) {
        Swal.fire({
          type: 'error',
          title: 'Invalid Request!',
          text:
            'Ooops... you dont have any workspace to add members to. Create a workspace and then proceed to adding members or subscribe to gain permission',
        });
        return;
      }

      const alloweds = this.workspaces.filter(workspace => {
        const memberCount = workspace.members.length;
        const check = memberCount >= limitCount;

        if (check) {
          this.$notify({
            group: 'main',
            type: 'native-error',
            title: 'Please Upgrade',
            text: `You have exceeded the maximum number of team members allowed in the <strong>${workspace.name}</strong> workspace.
            Please upgrade your subscription to be able to add more team members.`,
          });
        }

        return !check;
      });

      if (alloweds.length < 1) {
        return;
      }

      this.addingMember = true;

      this.$apollo
        .mutate({
          mutation: ADD_TEAM_MEMBER_MUTATION,
          variables: {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            email: this.form.email,
            role: this.form.role,
            type: this.form.type,
            workspaces: alloweds.map(w => w.id),
          },
          update: (store, { data: { addTeamMember } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              this.form.workspaces.forEach(id => {
                const spaceIndex = data.workspaces.findIndex(w => w.id === id);

                if (spaceIndex !== -1) {
                  const workspace = data.workspaces[spaceIndex];
                  workspace.members.push(...addTeamMember);
                }
              });

              // Write our data back to the cache.
              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.addingMember = false;

          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Success!',
            text: 'Team member has been added successfully.',
          });

          this.$bvModal.hide('AddTeamMemberModal');

          this.form.reset();
        })
        .catch(() => {
          this.addingMember = false;
        });
    },

    invokeRemoveMember(member) {
      this.memberInvokedForRemoval = member;

      this.$bvModal.show('RemoveTeamMemberModal');
    },

    removeTeamMember() {
      this.removingMember = true;
      const userId = this.memberInvokedForRemoval.user.id;

      this.$apollo
        .mutate({
          mutation: REMOVE_TEAM_MEMBER_MUTATION,
          variables: {
            userId,
            workspaceIds: this.workspaces.map(w => w.id),
          },
          update: (store, { data: { removeTeamMember } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              removeTeamMember.forEach(workspace => {
                const workIndex = data.fetchWorkspaces.findIndex(w => w.id === workspace.id);

                if (workIndex !== -1) {
                  data.fetchWorkspaces.splice(workIndex, 1, workspace);
                }
              });

              // Write our data back to the cache.
              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Success!',
            text: 'Team member has been removed successfully.',
          });

          this.$bvModal.hide('RemoveTeamMemberModal');
        })
        .catch(() => {
          this.removingMember = false;
        });
    },

    onHideRemoveTeamMembereModal() {
      this.removingMember = false;
      this.memberInvokedForRemoval = null;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.page__content {
  color: #000000;
  &-content {
    background-color: transparent;
  }
  input,
  select {
    border: 1.5px solid #b7e4c7;
    border-radius: 8px;
    height: 40px;
    &:focus,
    &:active {
      border-color: $primary;
    }
  }
  &-btn {
    border-radius: 0.9rem !important;
    padding: 12px 14px;
  }
  .f-12 {
    font-size: 12px;
  }
  .f-14 {
    font-size: 14px;
  }
}
.team__members {
  &-list {
    background: #ffffff;
    border: 1px solid #b7e4c7;
    padding: 20px;
    border-radius: 10px;
    color: #000000;
    .data {
      border-right: 2px solid #95d5b2;
      .name {
        font-weight: bold;
        font-size: 14px;
      }
      .role {
        color: #52b788;
        font-size: 12px;
      }
    }
  }
  &-item {
    position: relative;
    align-items: center;
    .remove_icon {
      position: absolute;
      top: -20px;
      right: 20px;
      opacity: 0;
      color: $danger;

      &:focus {
        box-shadow: none;
      }

      .icon {
        margin-top: 0;
      }
    }
    &:hover {
      .remove_icon {
        opacity: 1;
      }
    }
  }
  .empty {
    text-align: center;
  }
  &-btn {
    margin: 10px 0;
    border: 1px solid #40916c;
    color: #52b788;
    background: #d8f3dc;
    border-radius: 10px;
    padding: auto 14px;
  }
}
.add_member_modal,
.remove_member_modal {
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .modal-body {
    background: #f8f8f8;
    border-radius: 8px;
    border: none;
    input,
    select {
      border: 1.5px solid #b7e4c7;
      border-radius: 10px;
      height: 40px;
    }
  }
}
</style>
