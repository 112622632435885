<template>
  <div class="auth-module">
    <div v-if="validatingToken" class="d-flex flex-column align-items-center justify-content-center">
      <h5>Verifying your information</h5>
      <b-spinner variant="primary" small class="mr-2" />
    </div>
    <template v-else>
      <div class="text-center mb-5">
        <h4 class="font-weight-bold mb-3">Welcome! You are just one step away!</h4>
        <p class="m-0">
          To keep your account secured and fully accessible, <br />
          please set a password
        </p>
      </div>

      <b-form @submit.prevent="setPasswordAndJoin" @keydown="form.onKeydown($event)">
        <b-form-group class="mb-5">
          <b-form-input
            name="password"
            v-model="form.password"
            placeholder="Password"
            type="password"
            required
          ></b-form-input>
          <has-error :form="form" field="password" />
        </b-form-group>

        <b-form-group class="mb-4">
          <b-form-input
            name="password_confirmation"
            v-model="form.password_confirmation"
            placeholder="Confirm password"
            type="password"
            required
          ></b-form-input>
          <has-error :form="form" field="password_confirmation" />
        </b-form-group>

        <b-button class="py-3 font-weight-bold text-uppercase" variant="primary" type="submit" block>
          <b-spinner class="mr-2" v-if="form.busy" small />
          Join Now
        </b-button>
      </b-form>
    </template>
  </div>
</template>

<script>
import Form from 'vform';
import { VALIDATE_JOIN_TOKEN_MUTATION, JOIN_AND_SET_PASSWORD_MUTATION } from '../graphql/mutations';

export default {
  metaInfo() {
    return { title: 'Join SocialMediaAgency360' };
  },

  data: () => {
    return {
      validatingToken: false,
      form: new Form({
        password: '',
        password_confirmation: '',
      }),
    };
  },

  created() {
    this.validatingToken = true;
    const token = this.$route.query.token;

    this.$apollo
      .mutate({
        mutation: VALIDATE_JOIN_TOKEN_MUTATION,
        variables: {
          token,
        },
        context: {
          uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
        },
      })
      .then(({ data }) => {
        if (!data.validateJoinToken) {
          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'You have provided an invalid token. Please provide the valid token.',
          });
          this.$router.push({ name: 'signin' });
        }
        this.validatingToken = false;
      })
      .catch(({ graphQLErrors }) => {
        this.validatingToken = false;
        const alreadyJoined = graphQLErrors.find(err => err.message == 'AlreadyJoined');

        if (alreadyJoined) {
          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'You have already join SocialMediaAgency360. Please login with your credentials.',
          });
          this.$router.push({ name: 'signin' });
        }
      });
  },

  methods: {
    async setPasswordAndJoin() {
      this.form.busy = true;
      const token = this.$route.query.token;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: JOIN_AND_SET_PASSWORD_MUTATION,
          variables: { ...this.form.data(), token },
          context: {
            uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
          },
        });
        // // Save the token.
        this.$store.dispatch('auth/saveToken', { token: data.joinAndSetPassword });

        // Redirect home.
        this.$router.push({ name: 'workspaces' }, () => {
          this.$notify({
            group: 'main',
            type: 'native-success',
            text: 'Your Account has been created successfully',
          });
        });
      } catch ({ graphQLErrors: errors }) {
        this.form.busy = false;

        for (let i = 0; i < errors.length; i++) {
          const errorSet = errors[i];
          this.form.errors.set(errorSet.extensions.validation);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.btn-google,
.btn-facebook {
  font-weight: bold;

  &:active {
    outline: 0;
  }
}
</style>
