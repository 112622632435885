<template>
  <div class="auth-module">
    <section class="p-5">
      <div class="px-5">
        <div class="my-3">
          <span class="p-0 m-0">
            <svg-icon name="wave-hand" size="md" />
            Welcome back!
          </span>
          <h4 class="my-2 font-weight-bold">
            Sign In to Your Account
          </h4>
        </div>

        <div class="my-3">
          <!-- <SocialAuth
            :queryLoading="queryLoading"
            :fbLoading="facebookLoading"
            :twLoading="twitterLoading"
            :goLoading="googleLoading"
            @fbLogin="facebookLogin()"
            @twLogin="twitterLogin()"
            @goLogin="googleLogin()"
          /> -->
        </div>

        <div class="clear-fix py-5"></div>

        <b-form @submit.prevent="login" @keydown="form.onKeydown($event)">
          <b-form-group>
            <label for="email-field" class="f-12 text-dark ml-2">Email</label>
            <b-form-input
              id="email-field"
              class="auth-input"
              name="email"
              v-model="form.email"
              type="email"
              placeholder="email@example.com"
              required
            ></b-form-input>
            <has-error :form="form" field="email" />
          </b-form-group>

          <b-form-group class="position-relative">
            <label for="password-field" class="f-12 text-dark ml-2">Password</label>
            <b-form-input
              id="password-field"
              class="auth-input"
              name="password"
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="**********"
              required
            ></b-form-input>
            <has-error :form="form" field="password" />
            <span @click="showPassword = !showPassword" class="input-addon">
              <svg-icon name="eye-alt" size="md" />
            </span>
          </b-form-group>

          <div class="mt-4">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <input type="checkbox" id="remember-me" class="remember_input" v-model="remember" />
                <label for="remember-me">Remember Me?</label>
              </div>
              <span class="f-12">
                <router-link :to="{ name: 'reset' }">Forgot Password?</router-link>
              </span>
            </div>
            <b-button class="my-4 auth-btn font-weight-bold" type="submit">
              <b-spinner class="mr-2" v-if="form.busy" small />
              Login to account
            </b-button>
            <!-- <div class="f-12">Don't have an account? <router-link :to="{ name: 'signup' }">Sign Up</router-link></div> -->
          </div>
        </b-form>

        <Banner />

        <div class="terms__privacy">
          By continuing, you agree to our <a href="https://socialagency360.com/terms-and-conditions" target="_blank"><b>Terms & Conditions.</b></a> <br>
          Please read our <a href="https://socialagency360.com/privacy-policy" target="_blank"><b>Privacy Policy</b></a> to learn more about the way we use your information.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Form from 'vform';
// import SocialAuth from '~/components/SocialAuthOption';
import { LOGIN_MUTATION } from '~/graphql/mutations';
import Banner from '~/components/General/Banner.vue';
// import { FETCH_SSO_LINKS_QUERY } from '~/graphql/queries';

export default {
    name: "Login",
    components: { Banner },
    metaInfo() {
        return { title: "Sign In" };
    },
    data: () => ({
        // fetchSsoLinks: {},
        facebookLoading: false,
        twitterLoading: false,
        googleLoading: false,
        form: new Form({
            email: "",
            password: "",
        }),
        remember: false,
        showPassword: false,
    }),
    // apollo: {
    //   fetchSsoLinks: {
    //     query: FETCH_SSO_LINKS_QUERY,
    //     context: {
    //       uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
    //     },
    //   },
    // },
    computed: {
    // queryLoading() {
    //   return this.$apollo.queries.fetchSsoLinks.loading;
    // },
    },
    methods: {
        // facebookLogin() {
        //   this.facebookLoading = true;
        //   window.location = this.fetchSsoLinks.facebook;
        // },
        // twitterLogin() {
        //   this.twitterLoading = true;
        //   window.location = this.fetchSsoLinks.twitter;
        // },
        // googleLogin() {
        //   this.googleLoading = true;
        //   window.location = this.fetchSsoLinks.twitter;
        // },
        async login() {
            this.form.busy = true;
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: LOGIN_MUTATION,
                    variables: {
                        email: this.form.email,
                        password: this.form.password,
                    },
                    context: {
                        uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
                    },
                });
                // Save the token.
                this.$store.dispatch("auth/saveToken", {
                    token: data.token,
                    remember: this.remember,
                });
                if (this.$route.query.next) {
                    return this.$router.push(this.$route.query.next);
                }
                // Redirect home.
                this.$router.push({ name: "workspaces" });
            }
            catch ({ graphQLErrors: errors }) {
                this.form.busy = false;
                for (let i = 0; i < errors.length; i++) {
                    if (errors[i].message == "InvalidCredentials") {
                        this.form.errors.set("email", "Email or password is incorrect.");
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss">
.auth-module {
  .f-12 {
    font-size: 12px;
  }
  .text-gray {
    color: #d8f3dc;
  }
  .login-option {
    overflow: hidden;
    text-align: center;
    color: #d8f3dc;

    &:before,
    &:after {
      background-color: #b8b8b8;
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
  .auth-input {
    background: #eaffed;
    border-radius: 0.5rem !important;
    height: 40px;
    &:focus,
    &:active {
      border-color: #1b4332;
    }
  }

  .remember_input {
    display: none;
  }

  .remember_input + label:before {
    content: '\2714';
    border: 0.13em solid #52b788;
    border-radius: 50%;
    display: inline-block;
    width: 1.6em;
    height: 1.6em;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
  }

  .remember_input + label:active:before {
    transform: scale(0);
  }

  .remember_input:checked + label:before {
    background-color: MediumSeaGreen;
    border-color: MediumSeaGreen;
    color: #fff;
  }

  .input-addon {
    position: absolute;
    top: 40px;
    right: 18px;
  }
  .auth-btn {
    background-color: #b7e4c7;
    border-radius: 0.9rem !important;
    color: #2d6a4f;
    padding: 12px 14px;
  }

  .terms__privacy {
    text-align: center;
    position: absolute;
    bottom: 20px;
    font-size: 12px;
    a {
      font-size: 12px;
    }
  }
}
</style>
