<template>
  <div class="pb-4">
    <WorkspaceHeader />
    <b-container class=" pt-5">
      <div class="sa-dfy__card shadow mt-5 mb-5 ">
        <b-row>
          <b-col sm="auto" class="h-100 bg-black">
            <div class="sa-dfy__banner bg-black">
              <img
                src="https://t4555986.p.clickup-attachments.com/t4555986/d667e5e0-e811-4290-a472-29a0937c1716/Website%20(1).png"
              />
            </div>
          </b-col>
          <b-col align-self="center" class="py-3" col>
            <div>
              <h3 class="text-bold">DFY Website Designer</h3>
              <p class="text-regular max-w-700 italic mb-4">
                Get all your Agency assets read for use and start your agency in full power using our created tools.
              </p>

              <b-button @click="$router.push({ name: 'agency.websites' })" class="text-bold py-2 px-3 sa-dfy__btn">
                Get Started
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="sa-dfy__card  mt-4">
        <div v-for="(asset, idx) in assets" class="asset-border p-5" :key="idx+1">
          <b-row class="g-4">
            <b-col sm="auto">
              <div class="sa-dfy__assets-img">
                <img :src="asset.cover" alt="cover" />
              </div>
            </b-col>
            <b-col col align-self="center">
              <div>
                <h3 class="text-bold">{{ asset.title }}</h3>
                <p class=" pr-4" v-html="asset.desc"></p>
              </div>
            </b-col>
            <b-col sm="auto" align-self="center">
              <div>
                <b-button-group vertical class="rounded-5">
                  <a
                    class="btn btn-primary px-3"
                    :key="`download_${idx}`"
                    v-for="(_download, idx) in asset.actions.download"
                    variant="success"
                    :href="_download.value"
                    target="_blank"
                  >
                    {{ _download.key }}
                  </a>
                  <a
                    :key="`preview_${idx}`"
                    v-for="(_preview, idx) in asset.actions.preview"
                    :href="_preview.value"
                    class="btn btn-info px-4"
                    target="_blank"
                  >
                    {{ _preview.key }}
                  </a>
                  <a class="px-3 btn btn-warning" target="_blank" :href="asset.actions.watch_view">
                    Watch Demo
                  </a>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';
export default {
  name: 'DFYAssets',
  components: {
    WorkspaceHeader,
  },
  data: () => ({
    assets: [
      {
        cover:
          'https://t4555986.p.clickup-attachments.com/t4555986/a8069892-8fc5-410d-86bd-bf008bb11eed/Business%20Card.png',
        desc: `It includes Business cards Templates that will make Advertising your Agency business simpler. Simply download and customize the template to suit your feel, you can just add Your business name, logo, address, phone number and website to the Template.<br/><br/>
Follow the buttons below to Download the print ready graphics assets Files. You can also use the “tutorial Video” button to watch a quick demo video on how to Use and setup the Print Ready Graphics Assets.`,
        title: 'DFY Business Card',
        actions: {
          download: [
            {
              key: 'Download Asset',
              value: 'https://drive.google.com/drive/folders/1JDYkM-BJrLb6rasZth64bdaYX313YLqr?usp=sharing',
            },
          ],
          preview: [
            {
              key: 'Preview Asset',
              value: 'https://socialmediaagency.socialagency360.com/',
            },
            {
              key: 'Preview Asset 2',
              value: 'https://drive.google.com/file/d/1_0w1wmMWg55bnRKW8jW8Xp-8E-QPz-cM/view?usp=sharing',
            },
          ],
          watch_view: 'https://support.socialagency360.com/knowledge-base/how-to-install-set-up-dfy-website-asset/',
        },
      },

      {
        cover:
          'https://t4555986.p.clickup-attachments.com/t4555986/305b064f-80bc-49bb-901c-90ecbe6ed8d7/Flyer%20(1).png',
        desc: `It includes Flyer Templates that will make Advertising your business simpler. Simply download and customize the template to suit your feel, you can just add Your business name, logo, address, phone number and website to the Template. <br/><br />
        Follow the buttons below to see a Live Preview; or download the print ready graphics assets Files. You can also use the “tutorial Video” button to watch a quick demo video on how to Use and setup the Print Ready Graphics Assets.`,
        title: 'DFY Flyer',
        actions: {
          download: [
            {
              key: 'Download Asset',
              value: 'https://drive.google.com/file/d/1bVORd08RrN8_Czjt7f5tF1UGvudRWtXk/view?usp=sharing',
            },
          ],
          preview: [
            {
              key: 'Preview Asset',
              value: 'https://drive.google.com/file/d/1uSFAzOYMw21iaUQN1-koF2Z0eUpojD3P/view?usp=sharing',
            },
          ],
          watch_view: 'https://drive.google.com/drive/folders/1qk6msI62HjBU9yAYVn0JdJiSIJ9wPJpi?usp=sharing',
        },
      },
      {
        cover:
          'https://t4555986.p.clickup-attachments.com/t4555986/f3abc7de-63b9-4c01-b418-e29f993e1e45/Letterhead.png',
        desc: `It includes Letterhead Templates that will make Advertising your Business simpler. Simply download and customize the template to suit your feel, you can just add Your business name, logo, address, phone number and website to the Template. 
        <br /><br />Follow the button below to Download the print ready graphics assets Files. You can also use the “tutorial Video” button to watch a quick demo video on how to Use and setup the Print Ready Graphics Assets.`,
        title: 'DFY Letterhead',
        actions: {
          download: [
            {
              key: 'Download Asset',
              value: 'https://drive.google.com/file/d/14kHpBP4ZfoLuKevlfq1tE206zh2g821d/view?usp=sharing',
            },
          ],
          preview: [
            {
              key: 'Preview Asset',
              value: 'https://drive.google.com/file/d/1_oCc1kt_BIzdgu9xHF-_VHAH4d7i6oWg/view?usp=sharing',
            },
          ],
          watch_view: 'https://app.socialagency360.com/',
        },
      },
      {
        cover:
          'https://t4555986.p.clickup-attachments.com/t4555986/ea20ab3e-c41b-438d-9087-58e17bf21a4d/Proposal%20(1).png',
        desc: `Our DFY Proposal Setup comes 100% ready to go, with actual content, pages, contact and positioning that you can use instantly to sell your Service to clients. This comes Complete with Content, Images and every other element your Agency Business. <br /><br />
        Simply Open the Presentation Doc, Edit the Contact and Business Info or any other text and start Promoting today. Click on the button below to Download the Proposal Files. You can also use the “Tutorial Video” button to watch a quick demo video on how to Use and setup this Proposal.`,
        title: 'DFY Proposal',
        actions: {
          download: [
            {
              key: 'Download Asset',
              value: 'https://drive.google.com/drive/folders/1R-wpFrL4pwoCMNOgpu551LWLUyOrDFp5?usp=sharing',
            },
          ],
          preview: [],
          watch_view: 'https://app.socialagency360.com/',
        },
      },
      {
        cover:
          'https://t4555986.p.clickup-attachments.com/t4555986/fec87641-936c-4f9c-9ffc-deda21914030/Legal%20Contract.png',
        desc: `It includes a Legal Contract that has been vetted by an Attorney and ready to be used. Simply follow the buttons to Download the Legal Contract Files. You can also use the Tutorial Video Button to watch a quick demo video on how to Use and Set up this Legal Contract Asset.`,
        title: 'DFY Legal Contract',
        actions: {
          download: [
            {
              key: 'Download Asset',
              value: 'https://doc.clickup.com/4555986/p/h/4b16j-26080/ffb39234e454cd4',
            },
          ],
          preview: [],
          watch_view: 'https://app.socialagency360.com/',
        },
      },
      {
        cover:
          'https://t4555986.p.clickup-attachments.com/t4555986/f2d90b24-c859-4afe-ab82-c91bf1aa94b4/Brochure%20(1).png',
        desc: `It includes Complete email swipes from the Initial Contact, to Follow up, all the way to closing a Sale with a Client. Simply follow the buttons below to Download the Telemarketing Script Files. You can also use the “Tutorial Video” button to watch a quick demo video on how to Use and setup this Telemarketing Script asset.`,
        title: 'DFY Email Swipe',
        actions: {
          download: [
            {
              value: 'https://doc.clickup.com/4555986/p/h/4b16j-26100/3850e7790c634b3',
              key: 'Download Asset',
            },
          ],
          preview: [],
          watch_view: 'https://app.socialagency360.com/',
        },
      },
      {
        cover:
          'https://t4555986.p.clickup-attachments.com/t4555986/f2d90b24-c859-4afe-ab82-c91bf1aa94b4/Brochure%20(1).png',
        desc: `It includes Brochure Templates that will make Advertising Your business simpler. 
        Simply download and customize the template to suit your feel, you can just add Your business name, logo, address, phone number and website to the Template. Follow the buttons below to Download the print ready graphics assets Files. You can also use the “tutorial Video” button to watch a quick demo video on how to Use and setup the Print Ready Graphics Assets`,
        title: 'DFY Brochure',
        actions: {
          download: [
            {
              value: 'https://drive.google.com/file/d/1ZmSbntrF-of0btk5ORLP6UkUFJDbeh7E/view?usp=sharing',
              key: 'Download Asset',
            },
          ],
          preview: [
            {
              key: 'Preview Asset',
              value: 'https://drive.google.com/file/d/1Q5hcod4Wb0vwzk7Bs_ji491-tMt5k2HH/view?usp=sharing',
            },
          ],
          watch_view: 'https://drive.google.com/drive/folders/1qk6msI62HjBU9yAYVn0JdJiSIJ9wPJpi?usp=sharing',
        },
      },
      {
        cover:
          'https://t4555986.p.clickup-attachments.com/t4555986/af80ee02-269b-4157-b7db-6c2d00f8a59f/AD%20Banners%20(1).png',
        title: 'DFY Ads Banner',
        desc: `It includes Ad Banner Templates that will make Advertising your Business simpler. Simply download and customize the template to suit your feel, you can just add Your business name, logo, address, phone number and website to the Template.
Simply follow the buttons below to Download the print ready graphics assets Files. You can also use the “tutorial Video” button to watch a quick demo video on how to Use and setup the Print Ready Graphics Assets.`,
        actions: {
          watch_view: 'https://drive.google.com/drive/folders/1qk6msI62HjBU9yAYVn0JdJiSIJ9wPJpi?usp=sharing',
          download: [
            {
              key: 'Download Asset 160x600',
              value: 'https://drive.google.com/file/d/15__LBnQWSyXeWgsGI6BtZvzhf-9T21Sy/view?usp=sharing',
            },
            {
              key: 'Download Asset 250x250',
              value: 'https://drive.google.com/file/d/1aeK0VpDt-qtDG-MJdQB4YwJTcLbGiw6R/view?usp=sharing',
            },
            {
              key: 'Download Asset 728x90',
              value: 'https://drive.google.com/file/d/1j20GyZuMRnjZAPQlJW8hYdWcbOd6wBSs/view?usp=sharing',
            },
            {
              key: 'Download Asset 1200x628',
              value: 'https://drive.google.com/file/d/1LAdbmhvdZrLOcJKWo6dYK0E5Ml2CERDj/view?usp=sharing',
            },
          ],
          preview: [
            {
              key: 'Preview Asset 160x600',
              value: 'https://drive.google.com/file/d/1OVdX4g9cN4sXjKwuO8_pi09y92bEZBNd/view?usp=sharing',
            },
          ],
        },
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables';

$br: 20px;

.asset-border {
  border-bottom: 4px solid rgba($body-color, 0.3);
}
.rounded-5 {
  border-radius: 2rem !important;
}
.mb {
  &-6 {
    margin-bottom: 5rem;
  }
}
.sa {
  &-dfy {
    &__assets {
      &-img {
        max-width: 200px;
        overflow: clip;
        border-radius: 10px;
        // border: 2px solid $primary;
        // box-shadow: 0px 0px 11px -3px rgba(0, 0, 0, 0.3);

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__btn {
      background: $primary;
      color: white;
      border-radius: 20px;
      font-weight: 500;
    }
    &__card {
      border-radius: 13px;
      background: white;
      // border: 2px solid $primary;
      font-weight: 400;
      color: $primary;

      h5 {
        font-weight: 400;
      }
    }
    &__banner {
      width: 250px;
      border-radius: 10px 0 0 10px;
      overflow: clip;
      aspect-ratio: 1/1;
      position: relative;
      z-index: 1;
      // box-shadow: 0px 5px 10px 2px rgba($primary, 0.15);
      // box-shadow: 0px 10px 11px -3px rgba(0, 0, 0, 0.1);
      // transform: translateY(-50px);

      // &::before {
      //   --border-size: 2px;
      //   content: ' ';
      //   display: block;
      //   position: absolute;
      //   width: calc(100%);
      //   border-top-right-radius: inherit;
      //   border-top-left-radius: inherit;
      //   border-left: var(--border-size, 2px) solid $primary;
      //   border-right: var(--border-size, 2px) solid $primary;
      //   border-top: var(--border-size, 2px) solid $primary;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   height: calc(50px - 1rem);
      // }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.max {
  &-w {
    &-700 {
      max-width: 700px;
    }
  }
}
</style>
