<template>
  <div class="subscribe-page-wrap d-flex align-items-center">
    <b-container class="mb-5 mt-5">
      <b-modal
        id="VerifyModal"
        content-class="rounded-sm"
        modal-class="upgradeModal"
        size="xl"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
        static
        visible
        centered
      >
        <div v-if="user" class="p-5 text-center">
          <img class="my-4" width="300" src="@/assets/images/message_sent.svg" alt="" />
          <!-- <MessageSent class="w-8 mb-5" /> -->
          <h4 class="font-weight-bold text-center w-75 mx-auto mb-3">Verify your email address</h4>
          <p>
            A verification email has been sent to your email <span class="text-primary">{{ user.email }}</span> <br />
            please check your email and click the link provided in the email to complete your registration.
          </p>

          <p class="sub-text">
            If you do not receive the email within the next 5 minutes, <br />
            Use the button below to resend verification email.
          </p>

          <div class="mt-5">
            <b-button variant="secondary" class="shadow mr-2 px-5" pill :disabled="resending" @click="resend">
              <b-spinner v-if="resending" class="mr-1" small />
              Resend
            </b-button>
            <b-button variant="secondary" class="shadow px-5" pill :disabled="loggingout" @click="logout">
              <b-spinner v-if="loggingout" class="mr-1" small />
              Try A different account
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import app from '~/main';

// import MessageSent from '~/assets/images/message_sent.svg?inline';

import { RESEND_VERIFY_EMAIL_MUTATION, VERIFY_USER_EMAIL_MUTATION } from '~/graphql/mutations';

export default {
  // components: { MessageSent },

  async beforeRouteEnter(to, from, next) {
    if (to.query.token) {
      await app.$apollo
        .mutate({
          mutation: VERIFY_USER_EMAIL_MUTATION,
          variables: {
            token: to.query.token,
          },
          context: {
            uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
          },
        })
        .then(({ data }) => {
          app.$store.dispatch('auth/updateUser', { user: data.verifyUserEmail });

          app.$notify({
            group: 'main',
            type: 'native',
            text: 'Email verified. Thank you for verifying your email.',
          });

          next({ name: 'workspaces' });
        })
        .catch(({ graphQLErrors: errors }) => {
          const invalid = errors.find(err => err.message == 'InvalidToken');
          if (invalid) {
            app.$notify({
              group: 'main',
              type: 'native-error',
              text: 'Invalid token or token expired',
            });
          }

          next();
        });
    }

    next();
  },

  data: function() {
    return {
      resending: false,
      loggingout: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },

  methods: {
    resend() {
      this.resending = true;

      this.$apollo
        .mutate({
          mutation: RESEND_VERIFY_EMAIL_MUTATION,
          context: {
            uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
          },
        })
        .then(() => {
          this.resending = false;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Verification email has been resent successfully.',
          });
        })
        .catch(() => {
          this.resending = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    async logout() {
      this.loggingout = true;
      // Log out the user.
      await this.$store.dispatch('auth/logout');

      // Redirect to login.
      this.$router.push({ name: 'signin' }, () => (this.loggingout = false));
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.subscribe-page-wrap {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  overflow: auto;
  padding: 30px;
  background: transparent;

  &:before,
  &:after {
    position: fixed;
    height: 100%;
    width: 50%;
    top: 0;
    opacity: 0.6;
    content: '';
  }

  &:after {
    top: -47px;
    left: -21px;
    background: $primary;
    transform: rotate(26deg);
    width: 200%;
  }
}

.modal.upgradeModal {
  .modal-dialog {
    max-width: 1200px;
  }

  .close_upgrade {
    cursor: pointer;
    background: transparent;
    border: 0;
    float: right;
    outline: none;
    font-size: 15px;
    line-height: 30px;
    color: #989eb5;
    position: absolute;
    right: 10px;
    top: 10px;
    box-shadow: none;
  }

  .btn {
    font-weight: 500;
    text-decoration: none;
  }

  .sub-text {
    margin-top: 1.2rem;
    font-size: 0.9rem;
  }
}
</style>
