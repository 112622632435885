<template>
  <b-modal v-model="showModalEl" size="xl" hide-footer hide-header class="" centered @hide="hideModal">
    <div class="page-content">
      <div class="float-right ml-auto" @click="hideModal">
        <svg class="svg-icon modal-close-icon" width="30" height="30" viewBox="0 0 20 20">
          <path
            d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
          ></path>
        </svg>
      </div>
      <div class="tabbed">
        <input id="tab1" type="radio" name="css-tabs" checked />

        <ul class="tabs mx-auto">
          <li v-for="(part, index) in niche.sections" :key="part + index" class="tab">
            <label :id="`for-tab${index + 1}`" :for="`tab${index + 1}`" class="text-capitalize">
              {{ part.title }}
            </label>
          </li>
        </ul>

        <div v-for="(part, index) in niche.sections" :key="part + index" class="tab-content">
          <b-card-group deck class="mt-5">
            <b-card text-variant="none" class="niche-modal-item">
              <b-card-img-lazy
                :src="part.display_image"
                :alt="part.name"
                fluid-grow
                blank
                blank-src=""
                blank-color="transparent"
                class="w-100 overflow-hidden rounded-lg"
              >
              </b-card-img-lazy>
              <div v-if="part.tutorial_video_url">
                <b-button
                  :href="part.tutorial_video_url"
                  target="_blank"
                  class="m-4 text-white"
                  size="sm"
                  variant="primary"
                >
                  Watch Tutorial Video
                </b-button>
              </div>
            </b-card>

            <b-card text-variant="dark" class="niche-modal-item mt-3">
              <b-button size="sm" variant="primary">Details</b-button>
              <h4 class="font-weight-bold my-2 f-24 text-capitalize">
                {{ part.name }}
              </h4>
              <div class="py-3">
                <p class="f-16 gray-500" v-html="part.description"></p>
                <span class="text-primary">SOCIAL AGENCY 360</span>
              </div>

              <div class="row">
                <b-button
                  :href="part.preview_link"
                  target="_blank"
                  class="col mx-1 text-white bg-warning"
                  size="md"
                  variant="warning"
                >
                  Preview
                </b-button>
                <b-button
                  :href="part.asset_url"
                  target="_blank"
                  class="col mx-1 text-white"
                  size="md"
                  variant="primary"
                  :download="part.name"
                >
                  Download
                </b-button>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModalEl: {
      type: Boolean,
      default: false,
    },
    niche: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentSectionId: 0,
    };
  },
  methods: {
    hideModal() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.tabbed {
  overflow-x: hidden;

  [type='radio'] {
    display: none;
  }

  .tabs {
    margin: 2px auto;
    // width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;

    label {
      display: block;
      padding: 8px 32px;
      background: $gray-200;
      color: $white;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      transition: all 0.3s;
      margin: 2px;
    }
  }

  #for-tab1 {
    border-radius: 25px;
  }

  .tab:hover label {
    background: $primary;
  }

  .tab-content {
    display: none;
  }
}
.page-content {
  padding: 10px 20px;
}
/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type='radio']:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label {
  background: $primary;
}

.tabbed [type='radio']:nth-of-type(1):checked ~ .tab-content:nth-of-type(1) {
  display: block;
}

.niche-modal-item {
  background: none;
  padding: 0;
  border: 0;
  position: relative;
  .video-play-btn {
    position: absolute;
    top: 38%;
    left: 45%;
  }
}
</style>
