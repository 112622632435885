<template>
  <div>
    <div class="business__details">
      <b-row>
        <b-col cols="6" class="mx-auto mt-5">
          <Steps :current="current" />
          <div class="business__details-content my-5">
            <design>
              <template>
                <h5 class="font-weight-bold mb-0 pb-0">
                  We can't wait to meet you.
                  <svg-icon name="wink" class="ml-2" size="sm" />
                </h5>
                <span class="m-0 p-0 f-12">Please fill in the details below</span>

                <div class="form">
                  <b-form-group class="mt-3">
                    <p class="f-14">Logo</p>
                    <b-row>
                      <b-col cols="5">
                        <div
                          class="business__details-brand-logo-input"
                          :class="{
                            'business__details--collapsed': uploadingLogo || this.brand.logo,
                          }"
                        >
                          <input
                            ref="uploadLogo"
                            type="file"
                            autocomplete="off"
                            accept="image/*"
                            class="d-none"
                            id="brand_logo"
                            @change="handleLogoUpload"
                          />
                          <label for="brand_logo" class="business__details-brand-logo-upload f-12">
                            <svg-icon name="download-alt" size="md" />
                            Upload Logo
                          </label>
                          <b-form-text id="brand_logo-help"
                            >Recommended size: Less than 2MB (130px x 130px)</b-form-text
                          >
                        </div>
                        <div class="">
                          <div v-if="brand.logo" class="business__details-brand-logo-preview">
                            <img :src="brand.logo" alt="" class="picture" />
                          </div>
                          <div v-if="uploadingLogo" class="loading-wrap">
                            <b-spinner variant="primary" small></b-spinner>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form-group>

                  <b-form-group>
                    <label for="brand_tagline-field" class="f-14">Brand Tagline</label>
                    <b-form-input
                      id="brand_tagline-field"
                      class="auth-input text-lowercase"
                      v-model="brand.tagline"
                      type="text"
                      autocomplete="off"
                      @keyup="handleBrandTaglineKeyUp"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <label for="brand_description-field" class="f-14">Brand Description</label>
                    <b-form-input
                      id="brand_description-field"
                      class="auth-input"
                      v-model="brand.description"
                      type="text"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </template>
            </design>

            <div class="float-right mt-3 mb-5">
              <!-- <b-button class="font-weight-bold mr-2" @click="$router.push('/business/create')" variant="clear">
                <span class="mr-1">
                  <svg-icon name="arrow-left" size="md" />
                </span>
                Back
              </b-button> -->
              <b-button
                class="business__details-btn font-weight-bold"
                type="submit"
                :disabled="savingBrandDetails"
                @click="saveBrandDetails"
                variant="primary"
              >
                Next
                <b-spinner class="ml-2" v-if="savingBrandDetails" small />
                <svg-icon v-else name="arrow-right-alt" size="md" class="ml-2" />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import slugify from 'slugify';
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import Steps from '~/components/Setup/Steps.vue';
import Design from '~/components/Setup/UI-design.vue';

import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import { UPLOAD_WORKSPACE_LOGO_MUTATION, SAVE_WORKSPACE_MUTATION } from '~/graphql/mutations';

import app from '~/main';
import { can } from '~/utils/helpers';
import { isString } from 'lodash';

export default {
  components: { Steps, Design },

  async beforeRouteEnter(to, from, next) {
    const user = app.$store.state.auth.user;
    const sub = user ? user.subscription : null;
    const randomnumber = Math.floor(Math.random() * (100 - 0 + 1)) + 0;

    if (user.status == 'invited') {
      app.$notify({
        group: 'main',
        title: 'Please Upgrade',
        type: 'native-error',
        text:
          'You do not have an active subscription. Please purchase a subscription in order to create your own workspace.',
      });

      return next({ name: 'workspaces', query: { rf: randomnumber } });
    }

    const canView = user && can(user, 'bypass-subscription');
    const limit = parseInt(sub ? sub.plan.limits.workspaces : '');
    const limitCount = isNaN(limit) ? Infinity : limit;
    const usedCount = parseInt(sub ? sub.limitsUsage.workspaces : '');

    const hasExceeded = sub && usedCount >= limitCount;

    if (!canView && hasExceeded) {
      app.$notify({
        group: 'main',
        title: 'Please Upgrade',
        type: 'native-error',
        text:
          'You have reached the maximum number of workspaces allowed in your current plan. Please upgrade to create more workspaces.',
      });

      return next({ name: 'workspaces', query: { rf: randomnumber } });
    }

    next();
  },

  data() {
    return {
      current: 2,
      savingBrandDetails: false,
      uploadingLogo: false,
      timezone: moment.tz.guess(),
      isSlugDirty: false,
      invalidSlug: null,
      brand: {
        tagline: '',
        description: '',
        logo: '',
      },
    };
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),

    timezones() {
      const timezones = moment.tz.names();
      const timezoneNames = timezones
        .map(timezone => ({
          value: timezone,
          text: `${timezone} (GMT ${moment.tz(timezone).format('Z')})`,
        }))
        .sort();

      return [{ value: null, text: 'Select Timezone' }, ...timezoneNames];
    },
  },

  created() {},

  methods: {
    handleLogoUpload(event) {
      if (this.uploadingLogo) return;

      const file = event.target.files[0];

      if (!this.isValidFile(file)) return;

      this.uploadingLogo = true;

      this.$apollo
        .mutate({
          mutation: UPLOAD_WORKSPACE_LOGO_MUTATION,
          variables: { logo: file },
        })
        .then(({ data: { uploadWorkspaceLogo } }) => {
          this.uploadingLogo = false;

          this.brand.logo = uploadWorkspaceLogo.url;
        })
        .catch(error => {
          this.showValidationErrors(error);

          this.uploadingLogo = false;
        });
    },

    handleBrandTaglineKeyUp() {
      this.isSlugDirty = this.brand.tagline && this.brand.tagline !== slugify(this.brand.description);
      const regexp = new RegExp('^[0-9A-Za-z-]+$');
      if (this.brand.tagline && !regexp.test(this.brand.tagline)) {
        this.invalidSlug = false;
      } else {
        this.invalidSlug = null;
      }
    },

    isValidFile(file) {
      const imageFormats = ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg', 'image/webp'];

      const inValidType = !imageFormats.includes(file.type);

      if (inValidType) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Invalid file type',
          text: 'You can only upload an image',
        });
        return false;
      }

      const size = file.size / 1000;
      if (imageFormats.includes(file.type) && size > 2048) {
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Image too large',
          text: 'Image size must not exceed 2MB',
        });
        return false;
      }

      return true;
    },

    saveBrandDetails() {
      if (!this.validateForm()) return;

      this.savingBrandDetails = true;
      this.$apollo
        .mutate({
          mutation: SAVE_WORKSPACE_MUTATION,
          variables: {
            name: this.brand.description,
            slug: this.brand.tagline,
            logo: this.brand.logo,
            timezone: this.timezone,
          },
          update: (store, { data: { saveWorkspace } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_WORKSPACES_QUERY,
              });

              data.fetchWorkspaces.push(saveWorkspace);

              // Write our data back to the cache.
              store.writeQuery({
                query: FETCH_WORKSPACES_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Workspace successfully added.',
          });

          // const usedCount = parseInt(this.user.subscription.limitsUsage.workspaces);
          // this.$store.dispatch('auth/updateUser', {
          //   user: {
          //     ...this.user,
          //     subscription: {
          //       ...this.user.subscription,
          //       limitsUsage: {
          //         ...this.user.subscription.limitsUsage,
          //         workspaces: usedCount + 1,
          //       },
          //     },
          //   },
          // });

          this.$router.push({ name: 'teams' }, () => (this.savingBrandDetails = false));
        })
        .catch(error => {
          this.showValidationErrors(error);

          this.savingBrandDetails = false;
        });
    },

    showValidationErrors({ graphQLErrors }) {
      const validations = graphQLErrors.filter(err => err.message == 'validation');
      const taglineError = graphQLErrors.find(err => err.message == 'SlugExists');

      if (taglineError) {
        this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'You already have a brand with this tagline. Try a different tagline',
        });
      } else if (validations.length) {
        validations.forEach(err => {
          const errors = err.extensions.validation;
          for (let key in errors) {
            const fieldErrors = errors[key];

            this.$notify({
              group: 'main',
              type: 'error',
              title: `Invalid ${key}`,
              text: fieldErrors.join('<br />'),
            });
          }
        });
      } else {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'An error occurred while processing your request.',
        });
      }
    },

    validateForm() {
      if (!this.brand.logo) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please upload a brand logo',
        });

        return false;
      }

      if (!isString(this.brand.tagline)) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please enter a brand tagline',
        });

        return false;
      }

      if (this.brand.tagline) {
        const regexp = new RegExp('^[0-9A-Za-z-]+$');
        if (!regexp.test(this.brand.tagline)) {
          this.$notify({
            group: 'main',
            type: 'error',
            text: 'Tagline can only contain aplha numeric characters and -',
          });

          return false;
        }

        if (this.brand.tagline.length > 255) {
          this.$notify({
            group: 'main',
            type: 'error',
            text: 'Tagline character length must be less than or equal to 255',
          });

          return false;
        }
      }

      if (!this.brand.description) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Please enter a brand description',
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.business__details {
  color: #000000;
  &-content {
    background-color: transparent;
  }
  input {
    border: 1.5px solid #b7e4c7;
    border-radius: 8px;
    height: 40px;
    &:focus,
    &:active {
      border-color: $primary;
    }
  }
  &-btn {
    border-radius: 0.9rem !important;
    padding: 12px 14px;
  }
  &-brand-logo-upload {
    cursor: pointer;
    width: 80%;
    background-color: #e4e4e4;
    border: 2px solid #b7e4c7;
    border-radius: 15px;
    color: #40916c;
    font-weight: bold;
    padding: 10px 15px;
    transition: all 0.2s ease-in !important;
  }
  &--collapsed {
    display: none;
  }
  &-brand-logo-preview {
    width: 130px;
    height: 130px;
    margin: 0 auto;
    padding: 10px;
    .picture {
      width: 100%;
      height: 100%;
    }
  }
  .f-12 {
    font-size: 12px;
  }
  .f-14 {
    font-size: 14px;
  }
}
</style>
