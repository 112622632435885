<template>
  <div key="reload">
    <div class="row gap-4">
      <div class="col-md-4 mb-4">
        <b-btn class="create" @click="createNewAgency">
          <div>
            <svg-icon name="plus" size="lg" />
            <h6>Create Website</h6>
          </div>
        </b-btn>
      </div>
      <div v-for="(agency, idx) in agencies" :key="`${idx}.${Math.random()}`" class="col-md-4 mb-4">
        <div class="agency-item">
          <div class="agency-hover-item">
            <router-link
              :to="{
                name: 'agency.websites.edit',
                params: { id: agency.id },
              }"
            >
              <b-button style="box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);">
                <svg-icon name="edit" />
              </b-button>
            </router-link>
            <b-button
              style="box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);"
              @click="confirmDelete(agency.id)"
              variant="danger"
            >
              <svg-icon name="trash" />
            </b-button>
          </div>
          <div class="agency-item-img-box">
            <img :src="agency.template_cover" v-if="agency.template_cover" />
            <div v-else class="site-under-construction">
              Site Under Construction
            </div>
          </div>
          <div class="agency-name">
            {{ agency.name }}
          </div>
          <div class="agency-body">
            <span>{{ getDomain(agency) }}</span>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '~/components/SvgIcon.vue';
import { FETCH_DFY_WEBSITES_QUERY } from '~/graphql/queries';
import { ADD_DFY_WEBSITE_MUTATION, DELETE_DFY_WEBSITE_MUTATION } from '~/graphql/mutations';

export default {
  name: 'DFYWizard',
  components: { SvgIcon },
  data: () => ({
    reload: 0,
    modalShow: false,
    fetchDfyWebsites: [],
  }),

  apollo: {
    fetchDfyWebsites: {
      query: FETCH_DFY_WEBSITES_QUERY,
    },
  },

  computed: {
    agencies() {
      return this.fetchDfyWebsites;
    },
  },

  mounted() {
    if (this.$apollo.queries.fetchDfyWebsites) {
      this.$apollo.queries.fetchDfyWebsites.refetch();
    }
  },
  methods: {
    createNewAgency() {
      this.addWebsite().then(({ data }) => {
        this.$apollo.queries.fetchDfyWebsites.refetch();
        this.$router.push({
          name: 'agency.websites.edit',
          params: { id: data?.addDfyWebsite?.id },
        });
      });
    },
    async addWebsite() {
      return this.$apollo.mutate({
        mutation: ADD_DFY_WEBSITE_MUTATION,
      });
    },

    async deleteWebsite(id) {
      const response = await this.$apollo.mutate({
        mutation: DELETE_DFY_WEBSITE_MUTATION,
        variables: {
          id,
        },
      });

      return response.data.deleteDfyWebsite;
    },

    getDomain(agency) {
      return agency.sub_domain;
    },

    confirmDelete(id) {
      const $this = this;
      const agency = this.agencies.find(e => e.id == id);
      const h = this.$createElement;

      const titleVNode = h('div', { domProps: { innerHTML: 'Confirm Delete!' } });
      const msgVNode = h('div', {
        domProps: {
          innerHTML: 'Please confirm that you want to delete <b>' + (agency?.name || agency?.sub_domain) + '</b>',
        },
      });
      this.$bvModal
        .msgBoxConfirm([msgVNode], {
          title: [titleVNode],
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          headerClass: 'p-2 px-3 text-center border-bottom-0',
          footerClass: 'p-2 border-top-0',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async () => {
          await $this.deleteWebsite(+id);
          $this.$apollo.queries.fetchDfyWebsites.refetch();
        });
      // .catch(err => {
      //   // An error occurred
      // });
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/variables';
.agency {
  &-hover-item {
    position: absolute;
    right: 0;
    margin: 20px;
    display: inline-flex;
    gap: 0.5rem;

    & button {
      border-radius: 50%;
      aspect-ratio: 1/1;
      padding: 0px 5px;
    }
  }
  &-body {
    padding: 20px;
    text-align: center;

    & span {
      padding: 3px 10px;
      background-color: $light;
      border-radius: 10px;
    }
  }
  &-name {
    position: absolute;
    top: 0;
    font-size: 12px;
    margin: 20px;
    padding: 3px 10px;
    background-color: $light;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
  }
  &-item {
    position: relative;
    border-radius: 10px;
    overflow: clip;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    &-img-box {
      overflow: hidden;
      max-height: 200px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.create {
  border: 2px dashed rgba($body-color, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  > div {
    text-align: center;

    > h6 {
      font-weight: 400;
      margin-top: 20px;
    }
  }
}

.site-under-construction {
  background: grey;
  color: white;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
