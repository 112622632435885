<template>
  <div class="auth-module">
    <h4 class="font-weight-bold mb-4">Reset Your Password</h4>
    <b-form @submit.prevent="resetPassword" @keydown="form.onKeydown($event)">
      <b-form-group class="mb-4">
        <b-form-input
          name="password"
          v-model="form.password"
          placeholder="New Password"
          type="password"
          required
        ></b-form-input>
        <has-error :form="form" field="password" />
      </b-form-group>

      <b-form-group class="mb-4">
        <b-form-input
          name="password_confirmation"
          v-model="form.password_confirmation"
          placeholder="Confirm Password"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button variant="primary" type="submit" class="py-3 font-weight-bold text-uppercase" block>
        <b-spinner class="mr-2" v-if="form.busy" small /> Reset Password
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Form from 'vform';
import { RESET_PASSWORD_MUTATION } from '~/graphql/mutations';

export default {
  name: 'ResetPassword',

  metaInfo() {
    return { title: 'Reset Your Password' };
  },

  data() {
    return {
      form: new Form({
        token: this.$route.query.token || '',
        email: this.$route.query.email || '',
        password: '',
        password_confirmation: '',
      }),
    };
  },

  methods: {
    async resetPassword() {
      this.form.busy = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: RESET_PASSWORD_MUTATION,
          variables: {
            email: this.form.email,
            token: this.form.token,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
          },
          context: {
            uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
          },
        });
        // Save the token.
        this.$store.dispatch('auth/saveToken', {
          token: data.resetPassword,
          remember: this.remember,
        });

        this.$notify({
          group: 'main',
          type: 'native',
          text: 'Your password was reset successfully.',
        });

        // Redirect home.
        this.$router.push({ name: 'workspaces' });
      } catch ({ graphQLErrors: errors }) {
        this.form.busy = false;
        for (let i = 0; i < errors.length; i++) {
          if (errors[i].message == 'InvalidCredentials') {
            this.form.errors.set('password', 'Invalid Password reset token.');
          } else {
            const validations = errors[i].extensions.validation;
            Object.keys(validations).forEach(key => {
              this.form.errors.set(key, validations[key]);
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
