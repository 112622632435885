<template>
  <div class="subscribe-page-wrap d-flex align-items-center">
    <b-container class="mb-5 mt-5">
      <b-modal
        id="UpgradeModal"
        content-class="rounded-lg"
        modal-class="upgradeModal"
        size="xl"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
        static
        visible
      >
        <div class="py-5 px-4">
          <h5 class="font-weight-bold text-center w-75 mx-auto mb-5">
            Thank you for your interest in using Social Agency 360. Please upgrade your account to continue. Choose a
            plan that meets your requirements
          </h5>

          <b-button @click="handleClose" variant="clear" class="close_upgrade">
            <svg-icon name="close" size="md" />
          </b-button>

          <div class="text-center mb-4">
            <div class="discount_wrapper d-inline-block position-relative">
              <div class="toggle_btn_inner">
                <div
                  class="active_move active_left"
                  :class="{ active_left: activeCycle === 'month', active_right: activeCycle === 'year' }"
                ></div>
                <button
                  @click="setActiveBillingCycle('month')"
                  class="btn btn_round border_btn large_btn"
                  :class="{ active: activeCycle === 'month' }"
                >
                  <span>Monthly</span>
                </button>
                <button
                  @click="setActiveBillingCycle('year')"
                  class="btn btn_round border_btn large_btn"
                  :class="{ active: activeCycle === 'year' }"
                >
                  <span>Yearly</span>
                </button>
              </div>
              <span class="discount">20% Off</span>
            </div>
          </div>

          <div class="pricing__box">
            <div class="inner clear row">
              <div v-for="plan in computedPlans" :key="plan.id" class="price_box_3 col-md-3 col-sm-3">
                <div class="inner">
                  <h3 class="package">{{ plan.name }}</h3>
                  <div class="pricing_bg d-flex align-items-center justify-content-center">
                    <div class="bg_inner">
                      <h2 class="price">{{ getPlanAmount(plan) }}</h2>
                      <p v-if="activeCycle == 'year'" class="cross">{{ getMonthlyPrice(plan.name) }}</p>
                      <p class="duration">monthly</p>
                    </div>
                  </div>
                  <ul class="list_detail">
                    <li v-if="activeCycle == 'year'" class="yearly_billed">Billed Yearly</li>
                    <li>
                      <span>{{ plan.limits.workspaces }}</span> Workspaces
                    </li>
                    <li>
                      <span>{{ plan.limits.socialAccounts }}</span> Social Accounts
                    </li>
                    <li>
                      <span>{{ plan.limits.blogs }}</span> Blogs
                    </li>
                    <li>
                      <span>{{ plan.limits.topics == '*' ? 'Unlimited' : plan.limits.topicCount }}</span> Custom Topics
                    </li>
                    <li>
                      <span>{{ plan.limits.automations == '*' ? 'Unlimited' : plan.limits.automationCount }}</span>
                      Automation Campaigns
                    </li>
                    <li>
                      <span>{{ plan.limits.members == 0 ? 'No' : plan.limits.members }}</span> Team Members
                    </li>
                  </ul>
                  <div>
                    <b-button
                      v-if="user"
                      variant="outline-primary"
                      pill
                      class="pricing_btn"
                      :disabled="activePlan && activePlan.id === plan.id && !user.onGracePeriod"
                      @click="handleUpgrade(plan)"
                    >
                      {{ getPlanButtonText(plan) }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="cardModal"
        content-class="rounded-sm"
        centered
        static
        hide-header
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
      >
        <template v-slot:default="{ hide }">
          <div v-if="hasIncompletePayment" class="text-center p-5">
            <h5 class="font-weight-bold mb-3">Further Actions Required!</h5>
            <p>Please check your email for further payment confirmation instructions.</p>

            <b-button variant="primary" class="px-4 mt-3" @click="hide()">Okay</b-button>
          </div>
          <div v-else class="p-5">
            <StripeElement element-selector="#card-element">
              <template v-slot:default="{ loading: stripeLoading, focused, setupCard, errorMessage }">
                <h6 class="card-modal-title shadow mb-5">
                  <strong>Social Agency {{ selectedPlan.name }}</strong>
                </h6>

                <div class="my-4">
                  <div class="text-center my-4">
                    <h5>Your total is {{ getPlanTotal(selectedPlan) }}</h5>
                    <div class="text-muted small">Then {{ getPlanCycleAmount(selectedPlan) }}/{{ activeCycle }}</div>
                  </div>

                  <b-form-group label="">
                    <b-form-radio-group
                      v-model="useExistingCard"
                      :options="cardOptions"
                      name="radio-inline"
                    ></b-form-radio-group>
                  </b-form-group>

                  <div v-show="useExistingCard" class="shadow-sm">
                    <div v-if="$apollo.queries.paymentMethods.loading" class="text-center p-2">
                      <b-spinner variant="primary" small />
                    </div>
                    <div
                      v-else-if="!$apollo.queries.paymentMethods.loading && paymentMethods.length < 1"
                      class="text-center"
                    >
                      <small>
                        You do not have any existing card.
                        <a href="#" class="font-weight-bold" @click.prevent="useExistingCard = false">
                          Add New Card
                        </a>
                      </small>
                    </div>
                    <b-form-select v-else v-model="chosenCard">
                      <option value="">Choose an existing card</option>
                      <option v-for="card in paymentMethods" :key="card.id" :value="card.id">
                        ({{ capitalize(card.brand) }}) **** **** **** {{ card.last4 }}
                      </option>
                    </b-form-select>
                  </div>
                  <div v-show="!useExistingCard">
                    <b-form-group>
                      <b-form-input
                        v-model="nameOnCard"
                        :state="nameOnCardError"
                        class="card-name-input shadow-sm"
                        placeholder="Name on card"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                    <div v-if="stripeLoading" class="card-input-wrap text-center shadow-sm p-2">
                      <b-spinner variant="primary" small />
                    </div>
                    <div v-show="!stripeLoading">
                      <div id="card-element" class="card-input-wrap shadow-sm p-3" :class="{ focus: focused }"></div>
                      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
                    </div>
                  </div>
                </div>

                <div class="text-right mt-2">
                  <b-button @click="hide()" variant="clear" class="px-4 mr-3" pill>Cancel</b-button>
                  <b-button
                    @click="handlePayNow(setupCard)"
                    variant="secondary"
                    class="px-5 font-weight-bold"
                    pill
                    :disabled="payNowDisabled"
                  >
                    <b-spinner v-if="subscribing" class="mr-1" small />
                    Pay Now
                  </b-button>
                </div>
              </template>
            </StripeElement>
          </div>
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { capitalize } from 'lodash';
import { mapState, mapActions } from 'vuex';
import { can, formatCurrency } from '~/utils/helpers';
import StripeElement from '~/components/StripeElement';
import { PAYMENT_METHODS_QUERY } from '~/graphql/queries';
import { SUBSCRIBE_TO_PLAN_MUTATION } from '~/graphql/mutations';

export default {
  components: { StripeElement },

  data: function() {
    return {
      useExistingCard: true,
      cardOptions: [
        { text: 'Use existing card', value: true },
        { text: 'Use a new card', value: false },
      ],
      chosenCard: '',
      subscribing: false,
      activeCycle: 'month',
      setupIntent: {},
      selectedPlan: {},
      hasIncompletePayment: false,

      nameOnCard: '',
      nameOnCardError: null,

      paymentMethods: [],
    };
  },

  apollo: {
    paymentMethods: {
      query: PAYMENT_METHODS_QUERY,
      context: {
        uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
      },
      result({ data, loading }) {
        if (!loading && data.paymentMethods.length < 1) {
          this.useExistingCard = false;
        }
      },
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      plans: state => state.plan.plans,
    }),

    computedPlans() {
      return this.plans.filter(p => p.interval === this.activeCycle);
    },

    activePlan() {
      const planId = this.user && this.user.subscription ? this.user.subscription.plan.id : null;

      return this.plans.find(p => p.id === planId);
    },

    payNowDisabled() {
      return (this.useExistingCard && !this.chosenCard) || this.subscribing;
    },
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
    }),

    setActiveBillingCycle(cycle) {
      this.activeCycle = cycle;
    },

    getPlanAmount(plan) {
      const total = plan.amount;
      const perMonth = plan.interval === 'month' ? total : total / 12;

      return formatCurrency(perMonth, 0);
    },

    getMonthlyPrice(name) {
      const plan = this.plans.find(p => p.name === name && p.interval === 'month');

      return formatCurrency(plan.amount, 0);
    },

    getPlanCycleAmount(plan) {
      const amount = plan.amount;

      return formatCurrency(amount, 0);
    },

    getPlanTotal(plan) {
      const amount = plan.amount;

      return formatCurrency(amount);
    },

    getPlanButtonText(plan) {
      const isActive = this.activePlan && this.activePlan.id === plan.id;

      if (this.user.onGracePeriod && isActive) {
        return 'Resume';
      }
      const upgrade = this.activePlan && this.activePlan.amount > plan.amount ? 'Downgrade' : 'Upgrade';
      return isActive ? 'Active' : upgrade;
    },

    async handleUpgrade(plan) {
      this.selectedPlan = plan;
      this.$bvModal.show('cardModal');
    },

    async handlePayNow(setupCard) {
      if (!this.useExistingCard && !this.nameOnCard) {
        this.nameOnCardError = false;

        return;
      }

      this.subscribing = true;

      let paymentMethod;
      if (this.useExistingCard) {
        paymentMethod = this.chosenCard;
      } else {
        paymentMethod = await setupCard({ name: this.nameOnCard });
      }

      if (!paymentMethod) {
        this.subscribing = false;
        return;
      }

      const plan = this.selectedPlan;
      this.subscribeToPlan(plan, paymentMethod)
        .then(({ data }) => {
          this.subscribing = false;

          if (data.subscribeToPlan) {
            this.updateUser({ user: data.subscribeToPlan });
            this.nameOnCard = '';

            this.$notify({
              group: 'main',
              type: 'native',
              duration: 5000,
              title: 'Subscription Successful.',
              text: `You have successfully subscribed to ${plan.name} ${plan.interval}ly`,
            });

            this.$router.push({ name: 'workspaces' });
          }
        })
        .catch(({ graphQLErrors }) => {
          const incompletePayment = graphQLErrors.find(err => err.message === 'incomplete_payment');
          if (incompletePayment) {
            this.hasIncompletePayment = true;
          }

          this.subscribing = false;
        });
    },

    subscribeToPlan(plan, paymentMethod) {
      return this.$apollo.mutate({
        mutation: SUBSCRIBE_TO_PLAN_MUTATION,
        variables: {
          paymentMethod,
          planId: plan.id,
        },
        context: {
          uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
        },
      });
    },

    handleClose() {
      if (this.user && !this.user.subscribed && !can(this.user, 'bypass-subscription')) {
        this.logout();
      } else {
        this.$router.back();
      }
    },

    async logout() {
      // Log out the user.
      await this.$store.dispatch('auth/logout');

      // Redirect to login.
      this.$router.push({ name: 'signin' });
    },

    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.subscribe-page-wrap {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  overflow: auto;
  padding: 30px;
  background: transparent;

  &:before,
  &:after {
    position: fixed;
    height: 100%;
    width: 50%;
    top: 0;
    opacity: 0.6;
    content: '';
  }

  &:after {
    top: -47px;
    left: -21px;
    background: $primary;
    transform: rotate(26deg);
    width: 200%;
  }
}

.modal.upgradeModal {
  .modal-dialog {
    max-width: 1200px;
  }
  .modal-body {
    background: $primary;
    color: $secondary;
    border-radius: 14px;
  }

  .close_upgrade {
    cursor: pointer;
    background: transparent;
    border: 0;
    float: right;
    outline: none;
    font-size: 15px;
    line-height: 30px;
    color: #989eb5;
    position: absolute;
    right: 10px;
    top: 10px;
    box-shadow: none;
  }

  .discount_wrapper {
    .toggle_btn_inner {
      position: relative;
      display: inline-block;
      box-shadow: 0 0 16px 0 rgba(184, 189, 209, 0.5);
      overflow: hidden;
      background: #fff;
      border-radius: 30px;
      background-clip: padding-box;
      padding: 4px;

      .active_move {
        background: $primary;
        padding: 8px 20px;
        min-width: 194px;
        height: calc(100% - 6px);
        top: 3px;
        position: absolute;
        border-radius: 30px;
        background-clip: padding-box;
        transition: all 0.2s linear;

        &.active_left {
          left: 4px;
        }

        &.active_right {
          left: 187px;
        }
      }

      .active span {
        color: #fff;
      }

      button {
        position: relative;
        min-width: 190px;
        background: transparent;
        border: none;
        box-shadow: none;

        span {
          font-size: 18px;
          color: #3a4557;
          font-weight: 400;
          transition: all 0.2s linear !important;
        }
      }
    }

    .discount {
      padding: 10px 15px;
      text-align: center;
      border-radius: 25px;
      background-clip: padding-box;
      border: 2px solid $secondary;
      color: $secondary;
      display: block;
      position: absolute;
      font-size: 15px;
      right: -35px;
      top: 0;
      background: #fff;
    }
  }

  .price_box_3 .inner {
    border-radius: 20px;
    background-clip: padding-box;
    box-shadow: 0 0 16px 0 rgba(184, 189, 209, 0.3);
    background: $secondary;
    color: $primary;
    padding: 25px 15px;
    text-align: center;

    .package {
      font-size: 14px;
      font-weight: bold;
    }

    .pricing_bg {
      height: 120px;
      width: 150px;
      margin: 0 auto;

      .bg_inner {
        padding: 20px;
        background-color: $primary;
        border-radius: 50%;
        box-shadow: 0 0 16px 0 rgba(184, 189, 209, 0.5);
        height: 100px;
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .price {
      font-size: 20px;
      font-weight: 900;
      color: #fff;
      margin: 0;
    }

    .cross {
      margin: 0;
      font-size: 12px;
      color: #fff;
      position: relative;
      padding: 0 8px;

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        height: 1px;
        width: 100%;
        background: #fff;
        content: '';
      }
    }

    .duration {
      font-size: 12px;
      color: #fff;
      text-transform: capitalize;
      margin-bottom: 0;
      font-weight: bold;
    }

    ul,
    li,
    span {
      list-style: none;
      padding: 0;
      margin: 0;
      outline: 0;
    }

    span {
      font-weight: 700;
    }

    ul {
      li {
        font-size: 12px;
        font-weight: 500;
        color: #989eb5;
        margin-top: 12px;
        font-weight: bold;

        &.yearly_billed {
          color: $secondary;
        }
      }
    }
    .pricing_btn {
      margin-top: 25px;
      color: $primary;
      border: 2px solid $primary;
      text-transform: uppercase;
      padding: 7px 32px;
      font-size: 16px;
      letter-spacing: 0.2px;
      font-weight: 500;

      &:hover {
        color: $secondary;
        background-color: $primary;
      }

      &.active {
        border-color: $primary;
        color: $secondary;
        cursor: not-allowed;

        &:hover {
          color: $secondary;
          background-color: $primary;
        }
      }
    }
  }
}

.card-modal-title {
  background-color: $primary;
  color: white;
  padding: 18px;
  border-radius: 5px;
  text-align: center;
}
</style>
