<template>
  <div>
    <WorkspaceHeader v-if="$route.name !== 'agency.websites.editor'" />
    <div class="pt-5  text-center container-fluid bg-white pb-3">
      <div class=" p-3 mb-3 mt-5 container" v-if="$route.name !== 'agency.websites.editor'">
        <h3 class="head">Set up your social agency website in minutes!</h3>
        <h6 class="fw-light fs-4">
          Experience the convenience of our platform, complete with automatic subdomain allocation, complimentary
          hosting, and an included SSL certificate. Our service offers a selection of professionally designed templates,
          enabling you to establish your agency swiftly and effortlessly in just a matter of minutes.
        </h6>
      </div>
    </div>
    <div
      :class="{
        'container mt-5': $route.name !== 'agency.websites.editor',
      }"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';
export default {
  name: 'DFYWizard',
  components: {
    WorkspaceHeader,
  },
};
</script>

<style lang="scss">
.bg {
  &-line {
    background: url('../../../assets/images/new/strokes-2.svg');
    background-size: cover;
  }
}
.fw {
  &-light {
    font-weight: 300;
  }
}
.head {
  font-weight: 900;
}
</style>
