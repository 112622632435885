<template>
  <div class="error-box text-center bg-white w-50 mx-auto mt-5 shadow rounded p-5">
    <div class="error-body center-align">
      <h1>404</h1>
      <h3 class="my-4">Page Not Found!</h3>
      <b-button class="mt-3" :to="{ name: 'workspaces' }">
        <svg-icon name="arrow-left" class="mr-1" /> Go Back Home
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
