<template>
  <div class="reseller-page py-5">
    <b-container fluid class="p-5">
      <div class="text-center w-75 mx-auto">
        <h4 class="font-weight-bold mb-3">Reseller Dashboard</h4>
        <p class="text-muted">
          Welcome to the SocialMediaAgency360 reseller admin dashboard.
        </p>
      </div>

      <b-row class="mt-5">
        <b-col md="3">
          <b-list-group class="shadow-sm">
            <b-list-group-item class="d-flex justify-content-between align-items-center border-0">
              Reseller type
              <b-badge variant="primary" pill>{{ resellerType }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center border-0">
              License
              <b-badge variant="primary" pill>{{ resellerLicense }}</b-badge>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center border-0">
              Created accounts
              <b-badge variant="primary" pill>{{ fetchResellerUsers.length }}</b-badge>
            </b-list-group-item>
          </b-list-group>

          <b-button
            href="https://docs.google.com/document/d/1kv0DjGRTgs8Dfif8nsorHztMrWBjBi2xttBkzjCJWKQ/edit?usp=sharing "
            target="_blank"
            variant="outline-primary"
            class="d-flex justify-content-between align-items-center font-weight-bold mt-3 shadow"
            block
          >
            Access Reseller Resources
            <svg-icon name="arrow-right" class="mt-0" />
          </b-button>
        </b-col>
        <b-col>
          <b-tabs v-model="tabIndex" content-class="mt-3">
            <b-tab :title-link-class="linkClass(0)" active>
              <template v-slot:title>
                <svg-icon name="user" />
                Accounts
              </template>

              <div class="d-flex flex-wrap">
                <div class="add-user-box shadow-sm" :class="{ disabled: !licenseUsedUp }">
                  <div class="users-name">Add User</div>

                  <div id="addBtn" class="plus-wrap" @click="openAddUserModal">
                    <svg-icon name="plus" class="mt-0" />
                  </div>
                </div>

                <b-tooltip v-if="!resellerLicense" variant="danger" target="addBtn">
                  You do not have licence
                </b-tooltip>
                <b-tooltip v-else-if="licenseUsedUp" variant="danger" target="addBtn">
                  You have reach the maximum amount of user accounts included in your current license
                </b-tooltip>

                <template v-if="$apollo.queries.fetchResellerUsers.loading">
                  <div v-for="number in 7" :key="number" class="user-item">
                    <div class="p-4">
                      <skeleton-screen height="20px" width="150px" class="mx-auto mt-4 mb-2" />
                      <skeleton-screen height="14px" width="80px" class="mx-auto mb-3" />
                      <skeleton-screen height="80px" width="80px" class="mx-auto rounded-circle mb-4" />
                    </div>
                  </div>
                </template>

                <div v-for="user in fetchResellerUsers" :key="user.id" class="user-item shadow-sm">
                  <b-dropdown
                    variant="clear"
                    class="more-btn custom-dropdown"
                    offset="9"
                    toggle-class="p-0"
                    right
                    no-caret
                  >
                    <template v-slot:button-content>
                      <svg-icon name="more-vertical" />
                    </template>
                    <b-dropdown-item @click="openUserSubscriptionModal(user)">Subscription</b-dropdown-item>
                    <b-dropdown-item @click="openUpdateUserModal(user)">Edit</b-dropdown-item>
                    <b-dropdown-item @click="openDeleteUserModal(user)">Delete</b-dropdown-item>
                  </b-dropdown>

                  <div class="users-name">{{ user.firstName }} {{ user.lastName }}</div>
                  <p title="useremail@example.com" class="users-email">{{ user.email }}</p>

                  <div class="user-avatar">
                    <img :src="user.photoUrl_50" alt="" />
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title-link-class="linkClass(1)">
              <template v-slot:title>
                <svg-icon name="link" />
                Webhooks
              </template>

              <div class="webhooks-wrap">
                <div class="webhook-item bg-white px-4 shadow-sm">
                  <img src="@/assets/images/jvzoo.png" alt="" class="w-100" />

                  <div class="webhook-footer font-weight-bold">
                    JVZoo
                    <svg-icon v-if="isJvZooWebhookActive" name="dot-unread" class="ml-1 mt-0" />
                  </div>

                  <div v-if="!isJvZooWebhookActive" class="webhook-generate show-hover">
                    <spinner v-if="generateJvZooWebhookForm.busy" variant="primary" />
                    <template v-else>
                      <p>Generate an IPN URL for JVZoo.</p>

                      <b-form
                        method="POST"
                        @submit.prevent="generateJvZooWebhook"
                        @keydown="generateJvZooWebhookForm.onKeydown($event)"
                      >
                        <b-form-group label-size="sm" label="Verification Key">
                          <b-form-input
                            v-model="generateJvZooWebhookForm.key"
                            name="key"
                            placeholder="Secret key"
                            required
                          />

                          <has-error :form="generateJvZooWebhookForm" field="key" />
                        </b-form-group>

                        <b-button type="submit" variant="primary" class="px-4">Generate</b-button>
                      </b-form>
                    </template>
                  </div>
                  <div v-else class="webhook-urls show-hover">
                    <b-form-group label-size="sm">
                      <template v-slot:label>
                        <span id="jvzooLifetimeLabel">
                          Lifetime
                          <svg-icon name="duplicate" @click="doJvZooUrlCopy('lifetime')" />
                        </span>
                        <b-tooltip
                          target="jvzooLifetimeLabel"
                          variant="dark"
                          triggers="manual"
                          :show="jvZooUrlCopied === 'lifetime'"
                        >
                          Copied
                        </b-tooltip>
                      </template>
                      <b-form-input :value="jvZooWebhookUrls.lifetime" size="sm" class="w-100" readonly />
                    </b-form-group>
                    <b-form-group label-size="sm">
                      <template v-slot:label>
                        <span id="jvzooMonthlyLabel">
                          Monthly
                          <svg-icon name="duplicate" @click="doJvZooUrlCopy('monthly')" />
                        </span>
                        <b-tooltip
                          target="jvzooMonthlyLabel"
                          variant="dark"
                          triggers="manual"
                          :show="jvZooUrlCopied === 'monthly'"
                        >
                          Copied
                        </b-tooltip>
                      </template>
                      <b-form-input :value="jvZooWebhookUrls.monthly" size="sm" class="w-100" readonly />
                    </b-form-group>

                    <div class="text-center">
                      <b-button
                        variant="danger"
                        size="sm"
                        class="px-4"
                        @click="openDeleteWebhookModal(jvZooWebhookUrls)"
                      >
                        Delete Webhook URLs
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="AddNewUserModal"
      modal-class="new-user-form"
      body-class="py-4"
      content-class="rounded-sm"
      centered
      hide-header
      hide-footer
      @hidden="onHideAddNewUsereModal"
    >
      <template v-slot:default="{ hide }">
        <div class="p-4">
          <h5 class="font-weight-bold mb-3 d-flex justify-content-between">
            Add New User

            <b-button variant="link" size="sm" class="text-muted" :disabled="addUserForm.busy" @click="hide">
              <svg-icon name="close" />
            </b-button>
          </h5>

          <b-form method="POST" @submit.prevent="addNewUser" @keydown="addUserForm.onKeydown($event)">
            <b-form-group label="First name (required)">
              <b-form-input
                v-model="addUserForm.firstName"
                id="firstName"
                name="firstName"
                placeholder="First name"
                trim
              ></b-form-input>

              <has-error :form="addUserForm" field="firstName" />
            </b-form-group>
            <b-form-group label="Last name (required)">
              <b-form-input
                v-model="addUserForm.lastName"
                id="lastName"
                name="lastName"
                placeholder="Last name"
                trim
              ></b-form-input>

              <has-error :form="addUserForm" field="lastName" />
            </b-form-group>
            <b-form-group label="Email address (required)">
              <b-form-input
                v-model="addUserForm.email"
                id="email"
                name="email"
                placeholder="Email address"
                trim
              ></b-form-input>

              <has-error :form="addUserForm" field="email" />
            </b-form-group>

            <b-form-group label="Password (required)">
              <b-form-input
                v-model="addUserForm.password"
                id="password"
                name="password"
                type="password"
                placeholder=""
              ></b-form-input>

              <has-error :form="addUserForm" field="password" />
            </b-form-group>
            <b-form-group label="Re-type Password (required)">
              <b-form-input
                v-model="addUserForm.password_confirmation"
                id="confirmpassword"
                name="password_confirmation"
                type="password"
                placeholder=""
              ></b-form-input>

              <has-error :form="addUserForm" field="password_confirmation" />
            </b-form-group>

            <h5 class="font-weight-bold mt-4">Subscription</h5>
            <b-form-group label="Plan (required)">
              <b-form-select v-model="addUserForm.plan" id="plan" name="plan">
                <option value="monthly">Monthly</option>
                <option value="onetime">Onetime</option>
              </b-form-select>

              <has-error :form="addUserForm" field="plan" />
            </b-form-group>
            <b-form-group label="Amount in USD (required)">
              <b-form-input
                v-model="addUserForm.amount"
                id="amount"
                name="amount"
                placeholder="Amount in USD"
                trim
              ></b-form-input>
              <has-error :form="addUserForm" field="amount" />
            </b-form-group>

            <b-button variant="primary" type="submit" class="font-weight-bold px-4 mt-3" :disabled="addUserForm.busy">
              <b-spinner v-if="addUserForm.busy" small />
              Create
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="UserSubscriptionModal"
      modal-class="new-user-form"
      body-class="py-4"
      content-class="rounded-sm"
      centered
      hide-header
      hide-footer
      @hidden="onHideSubscriptionModal"
    >
      <template v-slot:default="{ hide }">
        <div class="p-4">
          <h5 class="font-weight-bold mb-3 d-flex justify-content-between">
            Subscription Details

            <b-button variant="link" size="sm" class="text-muted" :disabled="addUserForm.busy" @click="hide">
              <svg-icon name="close" />
            </b-button>
          </h5>
          <div>
            <p>
              <strong class="mr-2">Start:</strong>
              <span>{{ invokedUser.subscription.startsAt | dateFormat }}</span>
            </p>
            <p>
              <strong class="mr-2">Ends:</strong>
              <span>{{ invokedUser.subscription.endsAt | dateFormat }}</span>
            </p>
          </div>

          <b-form method="POST" @submit.prevent="updateSubscription" @keydown="subscriptionForm.onKeydown($event)">
            <h5 class="font-weight-bold mt-4">Update Subscription</h5>
            <b-form-group label="Plan (required)">
              <b-form-select v-model="subscriptionForm.plan" id="plan" name="plan">
                <option value="monthly">Monthly</option>
                <option value="onetime">Onetime</option>
              </b-form-select>

              <has-error :form="subscriptionForm" field="plan" />
            </b-form-group>
            <b-form-group label="Amount in USD (required)">
              <b-form-input
                v-model="subscriptionForm.amount"
                id="amount"
                type="number"
                name="amount"
                placeholder="Amount in USD"
                trim
              ></b-form-input>
              <has-error :form="subscriptionForm" field="amount" />
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              class="font-weight-bold px-4 mt-3"
              :disabled="subscriptionForm.busy"
            >
              <b-spinner v-if="subscriptionForm.busy" small />
              Save
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="UpdateUserModal"
      modal-class="new-user-form"
      body-class="py-4"
      content-class="rounded-sm"
      centered
      hide-header
      hide-footer
      @hidden="onHideUpdateUserModal"
    >
      <template v-slot:default="{ hide }">
        <div class="p-4">
          <h5 class="font-weight-bold mb-3 d-flex justify-content-between">
            Update User Account

            <b-button variant="link" size="sm" class="text-muted" :disabled="updateUserForm.busy" @click="hide">
              <svg-icon name="close" />
            </b-button>
          </h5>

          <b-form method="POST" @submit.prevent="updateUser" @keydown="updateUserForm.onKeydown($event)">
            <b-form-group label="First name (required)">
              <b-form-input
                v-model="updateUserForm.firstName"
                id="firstName"
                name="firstName"
                placeholder="First name"
                trim
              ></b-form-input>

              <has-error :form="updateUserForm" field="firstName" />
            </b-form-group>
            <b-form-group label="Last name (required)">
              <b-form-input
                v-model="updateUserForm.lastName"
                id="lastName"
                name="lastName"
                placeholder="Last name"
                trim
              ></b-form-input>

              <has-error :form="updateUserForm" field="lastName" />
            </b-form-group>
            <b-form-group label="Email address (required)">
              <b-form-input
                v-model="updateUserForm.email"
                id="email"
                name="email"
                placeholder="Email address"
                trim
              ></b-form-input>

              <has-error :form="updateUserForm" field="email" />
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              class="font-weight-bold px-4 mt-3"
              :disabled="updateUserForm.busy"
            >
              <b-spinner v-if="updateUserForm.busy" small />
              Save
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="DeleteUserModal"
      content-class="text-center"
      body-class="py-4"
      centered
      hide-header
      hide-footer
      @hidden="onHideDeleteUserModal"
    >
      <template v-slot:default="{ hide }">
        <template v-if="!deletingUser">
          <h5 class="font-weight-bold mb-3">Delete User Account</h5>
          <p class="mb-0">Are you sure you want to delete this user account</p>
          <p class="mb-4">This action cannot be undone.</p>

          <div>
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="deleteUser">Delete</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>

    <b-modal
      id="DeleteWebhookModal"
      content-class="text-center"
      body-class="py-4"
      centered
      hide-header
      hide-footer
      @hidden="onHideDeleteWebhookModal"
    >
      <template v-slot:default="{ hide }">
        <template v-if="!deletingWebhook">
          <h5 class="font-weight-bold mb-3">Delete Webhook URLs</h5>
          <p class="mb-0">Are you sure you want to delete this Webhook URLs</p>
          <p class="mb-4">This action cannot be undone.</p>

          <div>
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="deleteWebhookUrl">Delete</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Form } from 'vform';
import moment from 'moment';
import { mapState } from 'vuex';
import { FETCH_RESELLER_USERS_QUERY, RESELLER_WEBHOOK_URLS_QUERY } from '~/graphql/queries';
import {
  ADD_RESELLER_USER_MUTATION,
  DELETE_RESELLER_USER_MUTATION,
  UPDATE_RESELLER_USER_MUTATION,
  DELETE_RESELLER_WEBHOOK_URL_MUTATION,
  GENERATE_RESELLER_WEBHOOK_URL_MUTATION,
  UPDATE_RESELLER_USER_SUBSCRIPTION_MUTATION,
} from '~/graphql/mutations';

export default {
  data() {
    return {
      tabIndex: 0,

      addUserForm: new Form({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password_confirmation: '',

        plan: 'monthly',
        amount: '',
      }),

      subscriptionForm: new Form({
        plan: 'monthly',
        amount: '',
      }),

      updateUserForm: new Form({
        firstName: '',
        lastName: '',
        email: '',
      }),

      invokedUser: null,
      deletingUser: false,

      fetchResellerUsers: [],
      resellerWebhookUrls: [],
      generateJvZooWebhookForm: new Form({
        platform: 'jvzoo',
        key: '',
      }),

      jvZooUrlCopied: null,

      invokedWebhook: null,
      deletingWebhook: false,
    };
  },

  apollo: {
    fetchResellerUsers: {
      query: FETCH_RESELLER_USERS_QUERY,
    },

    resellerWebhookUrls: {
      query: RESELLER_WEBHOOK_URLS_QUERY,
    },
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),

    isJvZooWebhookActive() {
      return !!this.resellerWebhookUrls.find(webhook => webhook.platform === 'jvzoo');
    },

    jvZooWebhookUrls() {
      return this.resellerWebhookUrls.find(webhook => webhook.platform === 'jvzoo');
    },

    resellerType() {
      const hasSubscription = this.user.subscription;
      if (!hasSubscription) {
        return 'N/A';
      }

      const reseller_50 = this.user.subscription.addons.find(addon => addon.slug == 'reseller');
      if (reseller_50) {
        return 'Reseller Gold';
      }

      // const unlimited = this.user.subscription.addons.find(addon => addon.slug == 'reseller_unlimited');
      // if (unlimited) {
      //   return 'Unlimited';
      // }

      // const gold = this.user.subscription.addons.find(addon => addon.slug == 'reseller_gold');
      // if (gold) {
      //   return 'Gold';
      // }

      // const silver = this.user.subscription.addons.find(addon => addon.slug == 'reseller_silver');
      // if (silver) {
      //   return 'Silver';
      // }

      return 'N/A';
    },

    resellerLicense() {
      const hasSubscription = this.user.subscription;
      if (!hasSubscription) {
        return 0;
      }

      const reseller_50 = this.user.subscription.addons.find(addon => addon.slug == 'reseller');
      if (reseller_50) {
        return 50;
      }

      const unlimted = this.user.subscription.addons.find(addon => addon.slug == 'reseller_unlimited');
      if (unlimted) {
        return Infinity;
      }

      const gold = this.user.subscription.addons.find(addon => addon.slug == 'reseller_gold');
      if (gold) {
        return 100;
      }

      const silver = this.user.subscription.addons.find(addon => addon.slug == 'reseller_silver');
      if (silver) {
        return 30;
      }

      return 0;
    },

    licenseUsedUp() {
      return this.fetchResellerUsers.length >= this.resellerLicense;
    },
  },

  filters: {
    dateFormat(value) {
      if (!value) return '';

      return moment(value).format('MMM D, YYYY');
    },
  },

  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-white', 'font-weight-bold'];
      } else {
        return ['text-muted', 'font-weight-bold'];
      }
    },

    openAddUserModal() {
      if (this.licenseUsedUp) {
        return;
      }

      this.$bvModal.show('AddNewUserModal');
    },

    addNewUser() {
      if (this.licenseUsedUp) {
        return;
      }

      this.addUserForm.busy = true;
      this.$store.dispatch('layout/updateRestless', true);

      this.$apollo
        .mutate({
          mutation: ADD_RESELLER_USER_MUTATION,
          variables: {
            ...this.addUserForm.data(),
            amount: parseInt(this.addUserForm.amount, 10),
          },
          update: (store, { data: { addResellerUser } }) => {
            if (!addResellerUser) return;

            try {
              const data = store.readQuery({
                query: FETCH_RESELLER_USERS_QUERY,
              });

              data.fetchResellerUsers = [...data.fetchResellerUsers, addResellerUser];

              store.writeQuery({
                query: FETCH_RESELLER_USERS_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.addUserForm.busy = false;
          this.$store.dispatch('layout/updateRestless', false);

          this.$bvModal.hide('AddNewUserModal');

          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Operation successful',
            text: 'User account added successfully',
          });
        })
        .catch(({ graphQLErrors: errors }) => {
          this.addUserForm.busy = false;
          this.$store.dispatch('layout/updateRestless', false);

          const validations = errors.filter(err => err.message == 'validation');
          if (validations.length) {
            validations.forEach(err => {
              this.addUserForm.errors.set(err.extensions.validation);
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while processing your request.',
            });
          }
        });
    },

    openUserSubscriptionModal(user) {
      this.invokedUser = user;

      this.$bvModal.show('UserSubscriptionModal');
    },

    updateSubscription() {
      this.subscriptionForm.busy = true;
      this.$store.dispatch('layout/updateRestless', true);

      this.$apollo
        .mutate({
          mutation: UPDATE_RESELLER_USER_SUBSCRIPTION_MUTATION,
          variables: {
            id: this.invokedUser.id,
            ...this.subscriptionForm.data(),
            amount: parseInt(this.subscriptionForm.amount, 10),
          },
          update: (store, { data: { updateResellerUserSubscription } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_RESELLER_USERS_QUERY,
              });

              const index = data.fetchResellerUsers.findIndex(u => u.id === this.invokedUser.id);

              data.fetchResellerUsers = [
                ...data.fetchResellerUsers.slice(0, index),
                {
                  subscription: updateResellerUserSubscription,
                  ...data.fetchResellerUsers[index],
                },
                ...data.fetchResellerUsers.slice(index + 1),
              ];

              store.writeQuery({
                query: FETCH_RESELLER_USERS_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.subscriptionForm.busy = false;
          this.$store.dispatch('layout/updateRestless', false);

          this.$bvModal.hide('UserSubscriptionModal');

          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Operation successful',
            text: 'User subscription updated successfully',
          });
        })
        .catch(({ graphQLErrors: errors }) => {
          this.subscriptionForm.busy = false;
          this.$store.dispatch('layout/updateRestless', false);

          const validations = errors.filter(err => err.message == 'validation');
          if (validations.length) {
            validations.forEach(err => {
              this.subscriptionForm.errors.set(err.extensions.validation);
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while processing your request.',
            });
          }
        });
    },

    openUpdateUserModal(user) {
      this.invokedUser = user;

      this.updateUserForm.firstName = user.firstName;
      this.updateUserForm.lastName = user.lastName;
      this.updateUserForm.email = user.email;

      this.$bvModal.show('UpdateUserModal');
    },

    updateUser() {
      this.updateUserForm.busy = true;
      this.$store.dispatch('layout/updateRestless', true);

      this.$apollo
        .mutate({
          mutation: UPDATE_RESELLER_USER_MUTATION,
          variables: {
            id: this.invokedUser.id,
            ...this.updateUserForm.data(),
          },
          update: (store, { data: { updateResellerUser } }) => {
            try {
              const data = store.readQuery({
                query: FETCH_RESELLER_USERS_QUERY,
              });

              const index = data.fetchResellerUsers.findIndex(u => u.id === this.invokedUser.id);

              data.fetchResellerUsers = [
                ...data.fetchResellerUsers.slice(0, index),
                updateResellerUser,
                ...data.fetchResellerUsers.slice(index + 1),
              ];

              store.writeQuery({
                query: FETCH_RESELLER_USERS_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.updateUserForm.busy = false;
          this.$store.dispatch('layout/updateRestless', false);

          this.$bvModal.hide('UpdateUserModal');

          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Operation successful',
            text: 'User account updated successfully',
          });
        })
        .catch(({ graphQLErrors: errors }) => {
          this.updateUserForm.busy = false;
          this.$store.dispatch('layout/updateRestless', false);

          const validations = errors.filter(err => err.message == 'validation');
          if (validations.length) {
            validations.forEach(err => {
              this.updateUserForm.errors.set(err.extensions.validation);
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while processing your request.',
            });
          }
        });
    },

    openDeleteUserModal(user) {
      this.invokedUser = user;

      this.$bvModal.show('DeleteUserModal');
    },

    deleteUser() {
      if (!this.invokedUser) return;

      this.deletingUser = true;

      this.$apollo
        .mutate({
          mutation: DELETE_RESELLER_USER_MUTATION,
          variables: {
            id: this.invokedUser.id,
          },
          update: (store, { data: { deleteResellerUser } }) => {
            if (!deleteResellerUser) return;

            try {
              const data = store.readQuery({
                query: FETCH_RESELLER_USERS_QUERY,
              });

              const index = data.fetchResellerUsers.findIndex(u => u.id === this.invokedUser.id);

              if (index != -1) {
                data.fetchResellerUsers.splice(index, 1);
                // Write our data back to the cache.
                store.writeQuery({
                  query: FETCH_RESELLER_USERS_QUERY,
                  data,
                });
              }
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Operation successful',
            text: 'User account was deleted successfully.',
          });

          this.deletingUser = false;
          this.$bvModal.hide('DeleteUserModal');
        })
        .catch(() => {
          this.deletingUser = false;

          this.$notify({
            group: 'main',
            type: 'error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    onHideAddNewUsereModal() {
      this.addUserForm.reset();
    },

    onHideSubscriptionModal() {
      this.invokedUser = null;
      this.subscriptionForm.reset();
    },

    onHideUpdateUserModal() {
      this.invokedUser = null;
      this.updateUserForm.reset();
    },

    onHideDeleteUserModal() {
      this.deletingUser = false;
      this.invokedUser = null;
    },

    generateJvZooWebhook() {
      this.generateJvZooWebhookForm.busy = true;
      this.$store.dispatch('layout/updateRestless', true);

      this.$apollo
        .mutate({
          mutation: GENERATE_RESELLER_WEBHOOK_URL_MUTATION,
          variables: {
            ...this.generateJvZooWebhookForm.data(),
          },
          update: (store, { data: { generateResellerWebhookUrl } }) => {
            if (!generateResellerWebhookUrl) return;

            try {
              const data = store.readQuery({
                query: RESELLER_WEBHOOK_URLS_QUERY,
              });

              data.resellerWebhookUrls = [...data.resellerWebhookUrls, generateResellerWebhookUrl];

              store.writeQuery({
                query: RESELLER_WEBHOOK_URLS_QUERY,
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.generateJvZooWebhookForm.busy = false;
          this.$store.dispatch('layout/updateRestless', false);

          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Operation successful',
            text: 'JVZoo Webhook generated successfully',
          });

          this.generateJvZooWebhookForm.reset();
        })
        .catch(({ graphQLErrors: errors }) => {
          this.generateJvZooWebhookForm.busy = false;
          this.$store.dispatch('layout/updateRestless', false);

          const validations = errors.filter(err => err.message == 'validation');
          if (validations.length) {
            validations.forEach(err => {
              this.generateJvZooWebhookForm.errors.set(err.extensions.validation);
            });
          } else {
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while processing your request.',
            });
          }
        });
    },

    doJvZooUrlCopy(plan) {
      this.$copyText(this.jvZooWebhookUrls[plan]).then(
        () => {
          this.jvZooUrlCopied = plan;

          setTimeout(() => {
            this.jvZooUrlCopied = null;
          }, 1500);
        },
        () => {},
      );
    },

    openDeleteWebhookModal(webhook) {
      this.invokedWebhook = webhook;

      this.$bvModal.show('DeleteWebhookModal');
    },

    onHideDeleteWebhookModal() {
      this.deletingWebhook = false;
      this.invokedWebhook = null;
    },

    deleteWebhookUrl() {
      if (!this.invokedWebhook) return;

      this.deletingWebhook = true;

      this.$apollo
        .mutate({
          mutation: DELETE_RESELLER_WEBHOOK_URL_MUTATION,
          variables: {
            platform: this.invokedWebhook.platform,
          },
          update: (store, { data: { deleteResellerWebhookUrl } }) => {
            if (!deleteResellerWebhookUrl) return;

            try {
              const data = store.readQuery({
                query: RESELLER_WEBHOOK_URLS_QUERY,
              });

              const index = data.resellerWebhookUrls.findIndex(url => url.platform === this.invokedWebhook.platform);

              if (index != -1) {
                data.resellerWebhookUrls.splice(index, 1);
                // Write our data back to the cache.
                store.writeQuery({
                  query: RESELLER_WEBHOOK_URLS_QUERY,
                  data,
                });
              }
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            title: 'Operation successful',
            text: 'Webhook URLs was deleted successfully.',
          });

          this.deletingWebhook = false;
          this.$bvModal.hide('DeleteWebhookModal');
        })
        .catch(() => {
          this.deletingWebhook = false;

          this.$notify({
            group: 'main',
            type: 'error',
            text: 'An error occurred while processing your request.',
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.reseller-page {
  min-height: 100vh;

  .lg-link {
    &:hover {
      color: $primary;
    }
  }

  .add-user-box {
    width: 220px;
    height: 250px;
    background-color: $white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 20px;

    .users-name {
      font-weight: bold;
      font-size: 15px;
    }

    .plus-wrap {
      cursor: pointer;
      margin-top: 10px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        color: $primary;
      }
    }

    &:hover {
      .plus-wrap {
        background-color: rgba($primary, 0.1);
      }
    }

    &.disabled {
      .plus-wrap {
        opacity: 0.5;
      }
    }
  }

  .user-item {
    width: 220px;
    height: 250px;
    background-color: $white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;

    .more-btn {
      position: absolute;
      top: 10px;
      right: 5px;
      opacity: 0;

      .dropdown-toggle {
        box-shadow: none;
      }
    }

    .users-name {
      font-weight: bold;
      font-size: 15px;
    }

    .users-email {
      margin: 0;
      font-size: 14px;
      color: $gray-500;
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .user-avatar {
      margin-top: 10px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &:hover {
      .more-btn {
        opacity: 1;
      }
    }
  }

  .webhooks-wrap {
    .webhook-item {
      border-radius: 5px;
      width: 400px;
      height: 200px;
      position: relative;

      .webhook-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $light;
        border-top: 1px solid $gray-200;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .icon {
          color: $success;
          font-size: 10px;
        }
      }

      .show-hover {
        opacity: 0;
        transition: 0.3s all ease-out;
      }

      .webhook-generate {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba($black, 0.9);
        color: $white;
        text-align: center;
        border-radius: 5px;

        .form-control {
          &::placeholder {
            font-size: 1rem;
            color: $gray-500;
          }
        }
      }

      .webhook-urls {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba($black, 0.9);
        color: $white;
        text-align: right;
        border-radius: 5px;

        .form-group {
          width: 90%;

          .icon {
            cursor: pointer;
          }
        }
      }

      &:hover {
        .show-hover {
          opacity: 1;
        }
      }
    }
  }
}

.new-user-form {
  .form-control {
    &::placeholder {
      color: $gray-500;
      font-size: 14px;
    }
  }
}
</style>
