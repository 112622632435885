<template>
  <b-modal
    modal-class="dashboard-tutorial-modal"
    :visible="visible"
    id="DashboardTutorialModal"
    centered
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    @hide="handleOnHide"
  >
    <div slot="modal-header" slot-scope="{ close }">
      Learn to Use SocialMediaAgency360

      <button @click="close()" class="panel-header-button"><svg-icon name="close" size="sm" /></button>
    </div>
    <b-container>
      <b-row>
        <b-col cols="10" class="mx-auto main-content">
          <div class="px-4 mt-5">
            <p class="mt-4 main-text">{{ overview.description }}</p>

            <div class="video-player-wrap">
              <iframe
                width="100%"
                height="345"
                src="https://www.youtube.com/embed/gWwjd8jqfQs?si=0CI3ZaRmEVSeuBWk"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <p class="mt-2 small">
              Learn how to navigate and use SocialMediaAgency360
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div slot="modal-footer" slot-scope="{ close }">
      <div class="text-right">
        <div class="d-flex align-items-center">
          <b-form-checkbox v-model="neverShowAgain" class="mr-4 tutorial-toggle"> Never show again </b-form-checkbox>
          <b-button @click="close()" class="px-4" size="sm" variant="success">Let's Go</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SAVE_USER_OPTIONS_MUTATION } from '~/graphql/mutations';

export default {
  name: 'dashboard-tutorial-modal',

  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      neverShowAgain: false,
      activeStepIndex: 0,
      overview: {
        id: 1,
        title: 'Overview',
        description: 'Welcome to SocialAgency 360.',
        image: 'video-placeholder.png',
      },
    };
  },

  computed: {
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
    ...mapState('auth', {
      user: state => state.user,
      options: state => state.user.options,
    }),
  },

  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
    }),

    close() {
      this.$bvModal.hide('DashboardTutorialModal');
    },

    handleOnHide() {
      if (this.neverShowAgain) {
        this.$apollo
          .mutate({
            mutation: SAVE_USER_OPTIONS_MUTATION,
            variables: {
              options: {
                ...this.options,
                onboarding: {
                  ...this.options.onboarding,
                  homepage: false,
                },
              },
            },
            context: {
              uri: `${process.env.VUE_APP_API_ROOT}/gql/user`,
            },
          })
          .then(({ data }) => {
            this.updateUser({ user: data.user });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.dashboard-tutorial-modal {
  .modal-header {
    background-color: $primary;
    color: $white;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    font-weight: bold;
    padding: 0.7rem 1rem;

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .panel-header-button {
        .icon {
          fill: #fff;
        }
      }
    }
  }
  .modal-content {
    border-radius: 0.4rem;
    border: none;

    .modal-body {
      padding: 0;
    }

    .main-content {
      min-height: 510px;

      .btn-arrow {
        color: $gray-500;
      }

      .main-text {
        font-size: 0.9rem;
      }

      .video-player-wrap {
        min-height: 250px;
        width: 100%;
        background-color: $gray-200;
      }
    }
  }
  .modal-footer {
    .btn {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
</style>
